import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { Separator } from '~/components';
import { useContent } from '~/content/hooks';
import { db } from '~/db';
import { getOrgId } from '~/org';
import { cn } from '~/style';
import { formatDateTime } from '~/utils';
import { useParams } from '~/utils/hooks';

export const Alerts = () => {
  const { incidentId } = useParams<{ incidentId: string }>();
  const orgId = getOrgId();
  const content = useContent();
  const { data: alerts } = useQuery({
    queryKey: ['alerts'],
    queryFn: async () => {
      const alerts = await db.alert.list({
        data: {
          orgId,
          incidentId,
        },
        orderByKey: 'created',
        orderByValue: 'desc',
      });
      return alerts;
    },
  });

  if (!alerts) return null;

  return (
    <div className="grid gap-6">
      <div className="mx-auto mb-4 w-full max-w-4xl">
        <h1 className="text-2xl font-bold md:text-3xl">
          {content.get('alerts')} ({alerts.length})
        </h1>
      </div>

      {alerts.length > 0 ? (
        <div className="mx-auto w-full max-w-4xl overflow-hidden rounded-lg border">
          {alerts.map((alert, alertIndex) => {
            return (
              <div className="hover:bg-muted/40" key={alert.id}>
                {alertIndex !== 0 && <Separator />}

                <Link to={`/incidents/${incidentId}/alerts/${alert.id}`}>
                  <div className="flex flex-col gap-2 px-8 py-6 lg:flex-row lg:items-center lg:gap-14 lg:py-6 xl:py-8">
                    <div>
                      {formatDateTime(alert.updated, 'HH:mm - D MMM YYYY')}
                    </div>

                    <div
                      className={cn(
                        'ml-auto inline-block rounded-full px-5 py-1 text-center text-sm font-semibold',
                        {
                          'bg-[rgb(43,172,110)] text-white':
                            alert.status === 'complete',
                          'bg-gray-600 text-white': alert.status !== 'complete',
                        },
                      )}
                    >
                      {alert.status === 'complete'
                        ? content.get('sent')
                        : content.get('sending')}
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="mx-auto w-full max-w-4xl rounded-lg border py-40 text-center text-lg font-medium text-muted-foreground">
          {content.get('noAlertsSent')}
        </div>
      )}
    </div>
  );
};
