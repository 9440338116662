import { defineContent } from '~/content';

export default {
  recentActions: defineContent({
    default: 'Recent actions',
    'de-DE': 'Letzte Aktionen',
    'es-ES': 'Acciones recientes',
  }),
  history: defineContent({
    default: 'History',
    'de-DE': 'Verlauf',
    'es-ES': 'Historial',
  }),
};
