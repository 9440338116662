import { useMutation } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { analytics } from '~/analytics';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Badge,
  Button,
  OriginalButton,
  Separator,
} from '~/components';
import { useContent } from '~/content/hooks';
import { db, type Incident as IncidentType } from '~/db';
import { IncidentRecording } from '~/incident/components';
import { useIncidentData } from '~/incident/hooks';
import { useOrg } from '~/org/hooks';
import { sentry } from '~/sentry';
import { Updates } from '~/update/components';
import { formatDate } from '~/utils';
import { useParams } from '~/utils/hooks';

export const Incident = () => {
  const { incidentId } = useParams<{ incidentId: string }>();
  const { isOwner, org } = useOrg();
  const content = useContent();
  const [incident, setIncident] = useState<IncidentType | null>(null);
  const navigate = useNavigate();
  const data = useIncidentData(incident);

  const resolve = useMutation({
    mutationFn: async () => {
      if (!org) return;

      const isResolved = !incident?.isResolved;

      analytics.track(isResolved ? 'incident.resolve' : 'incident.unresolve');

      await db.incident.update({
        id: incidentId,
        data: {
          orgId: org.id,
          isResolved,
        },
      });
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  const remove = useMutation({
    mutationFn: async () => {
      if (!org) return;

      await db.incident.delete({
        id: incidentId,
        data: {
          orgId: org.id,
        },
      });
    },
    onSuccess: () => {
      analytics.track('incident.delete');

      navigate('/incidents');
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  useEffect(() => {
    if (!org) return;

    const unsubscribe = db.incident.subscribe({
      id: incidentId,
      onUpdate: setIncident,
      data: {
        orgId: org.id,
      },
    });

    return () => {
      unsubscribe();
    };
  }, [org, incidentId]);

  if (!incident) return null;

  return (
    <div className="mx-auto w-full max-w-4xl space-y-6">
      <div className="rounded-lg border">
        <>
          <div className="bg-muted/50 px-4 py-3 text-sm font-medium">
            {content.get('incident')}
          </div>

          <div className="flex flex-col gap-6 p-6 md:flex-row md:items-center md:gap-10">
            <div className="h-full space-y-3">
              {incident.isResolved && <Badge>{content.get('resolved')}</Badge>}

              <h1 className="text-2xl font-bold md:text-3xl md:leading-normal">
                {data('title')}
              </h1>
            </div>

            {!incident.isResolved && (
              <div className="h-full md:ml-auto">
                <Separator className="mb-6 md:hidden" />

                <Link to={`/incidents/${incident.id}/send-alert`}>
                  <Button className="w-full">{content.get('sendAlert')}</Button>
                </Link>
              </div>
            )}
          </div>
        </>

        <Separator />

        <>
          <div className="space-y-6 p-6">
            <div className="space-y-2">
              <div className="font-bold">{content.get('date')}</div>

              <div className="text-muted-foreground">
                {formatDate(incident.date)}
              </div>
            </div>

            {data('location') && (
              <div className="space-y-2">
                <div className="font-bold">{content.get('location')}</div>

                <div className="text-muted-foreground">{data('location')}</div>
              </div>
            )}

            <div className="space-y-2">
              <div className="font-bold">{content.get('description')}</div>

              {data('description') && (
                <div className="text-muted-foreground">
                  {data('description')}
                </div>
              )}
            </div>

            {/* {incident.latestAlert && (
              <div className="space-y-2">
                <div className="font-bold">Last sent alert</div>

                <Link
                  href={`/incidents/${incident.id}/alerts/${incident.latestAlert.id}`}
                >
                  <p className="mt-2 inline-block text-muted-foreground hover:underline">
                    {convertUnixToDate(
                      incident.latestAlert.updated,
                      'HH:mm - D MMM YYYY',
                    )}
                  </p>
                </Link>
              </div>
            )} */}
          </div>
        </>

        {data('summary') && (
          <>
            <Separator />

            <div className="space-y-6 p-6">
              <div className="font-bold">{content.get('summary')}</div>

              <div className="leading-relaxed text-muted-foreground">
                {data('summary')}
              </div>
            </div>

            <IncidentRecording incidentId={incidentId} />
          </>
        )}

        <Separator />

        {isOwner() && (
          <>
            <div className="flex flex-col gap-6 p-6 md:flex-row md:p-8">
              <Link to={`/incidents/${incident.id}/alerts`}>
                <Button className="w-full" variant="outline" size="sm">
                  {content.get('viewAlerts')}
                </Button>
              </Link>

              {!incident.isResolved && (
                <Link to={`/incidents/${incident.id}/edit`}>
                  <Button className="w-full" variant="outline" size="sm">
                    {content.get('edit')}
                  </Button>
                </Link>
              )}

              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <Button
                    variant="outline"
                    size="sm"
                    disabled={resolve.isPending}
                  >
                    {resolve.isPending ? (
                      <Loader2 className="h-6 w-6 animate-spin" />
                    ) : incident.isResolved ? (
                      content.get('unresolve')
                    ) : (
                      content.get('resolve')
                    )}
                  </Button>
                </AlertDialogTrigger>

                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>
                      {content.get('areYouSure')}
                    </AlertDialogTitle>

                    <AlertDialogDescription className="pb-6">
                      {incident.isResolved
                        ? content.get('actionUnresolveIncident')
                        : content.get('actionResolveIncident')}
                    </AlertDialogDescription>
                  </AlertDialogHeader>

                  <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>

                    <AlertDialogAction asChild>
                      <OriginalButton
                        onClick={() => {
                          resolve.mutate();
                        }}
                      >
                        {incident.isResolved
                          ? content.get('unresolve')
                          : content.get('resolve')}
                      </OriginalButton>
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>

              {incident.isResolved && (
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <Button
                      variant="destructive"
                      size="sm"
                      disabled={remove.isPending}
                    >
                      {remove.isPending ? (
                        <Loader2 className="h-6 w-6 animate-spin" />
                      ) : (
                        content.get('delete')
                      )}
                    </Button>
                  </AlertDialogTrigger>

                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>
                        {content.get('areYouSure')}
                      </AlertDialogTitle>

                      <AlertDialogDescription className="pb-6">
                        {content.get('actionDeleteIncident')}
                      </AlertDialogDescription>
                    </AlertDialogHeader>

                    <AlertDialogFooter>
                      <AlertDialogCancel>
                        {content.get('cancel')}
                      </AlertDialogCancel>

                      <AlertDialogAction asChild>
                        <OriginalButton
                          onClick={() => {
                            remove.mutate();
                          }}
                        >
                          {content.get('deleteIncident')}
                        </OriginalButton>
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              )}
            </div>
          </>
        )}
      </div>

      <Updates className="pt-10" />
    </div>
  );
};
