export const formatPath = (userId: string, path: string, data?: any) => {
  return path.replace(/{(\w+)}/g, (_, key) => {
    if (key === 'userId') {
      return userId;
    }

    if (data && key in data) {
      return data[key];
    }

    return `{${key}}`;
  });
};
