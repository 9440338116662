import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Separator } from '~/components';
import { useContent } from '~/content/hooks';
import { db, Incident } from '~/db';
import { getOrgId } from '~/org';
import { formatDateTime, numberWithCommas } from '~/utils';

export const ResolvedIncidents = () => {
  const [incidents, setIncidents] = useState<Incident[]>([]);
  const content = useContent();

  useEffect(() => {
    const orgId = getOrgId();
    const unsubscribe = db.incident.subscribeList({
      data: {
        orgId,
      },
      where: {
        key: 'isResolved',
        operator: '==',
        value: true,
      },
      onUpdate: setIncidents,
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="mx-auto w-full max-w-4xl space-y-6">
      <div className="flex flex-col gap-6 rounded-lg border p-6 md:flex-row md:items-center">
        <h1 className="text-2xl font-bold">
          {content.get('resolved')} ({numberWithCommas(incidents.length)})
        </h1>
      </div>

      {incidents.length > 0 ? (
        <div className="mx-auto w-full max-w-4xl overflow-hidden rounded-lg border">
          {incidents.map((incident, incidentIndex) => {
            return (
              <div className="hover:bg-muted/40" key={incident.id}>
                {incidentIndex !== 0 && <Separator />}

                <Link to={`/incidents/${incident.id}`}>
                  <div className="flex flex-col gap-2 px-8 py-6 lg:flex-row lg:items-center lg:gap-14 lg:py-6 xl:py-8">
                    <div className="font-semibold">{incident.title}</div>

                    <div className="whitespace-nowrap text-muted-foreground lg:ml-auto">
                      {formatDateTime(incident.updated, 'HH:mm - D MMM YYYY')}
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="text-balance rounded-lg border px-6 py-40 text-center text-lg font-medium text-muted-foreground">
          {content.get('noIncidentsResolved')}
        </div>
      )}
    </div>
  );
};
