import { defineContent } from '~/content';

export default {
  incidents: defineContent({
    default: 'Incidents',
    'de-DE': 'Vorfälle',
    'es-ES': 'Incidentes',
  }),
  incident: defineContent({
    default: 'Incident',
    'de-DE': 'Vorfall',
    'es-ES': 'Incidente',
  }),
  createIncident: defineContent({
    default: 'Create incident',
    'de-DE': 'Vorfall erstellen',
    'es-ES': 'Crear incidente',
  }),
  reportNewIncident: defineContent({
    default: 'Report new incident',
    'de-DE': 'Neuen Vorfall melden',
    'es-ES': 'Reportar nuevo incidente',
  }),
  noIncidentsReported: defineContent({
    default: 'No incidents have been reported',
    'de-DE': 'Es wurden keine Vorfälle gemeldet',
    'es-ES': 'No se han reportado incidentes',
  }),
  viewResolved: defineContent({
    default: 'View resolved',
    'de-DE': 'Angezeigte Vorfälle',
    'es-ES': 'Ver resueltos',
  }),
  resolved: defineContent({
    default: 'Resolved',
    'de-DE': 'Gelöst',
    'es-ES': 'Resuelto',
  }),
  noIncidentsResolved: defineContent({
    default: 'No incidents have been resolved',
    'de-DE': 'Es wurden keine Vorfälle gelöst',
    'es-ES': 'No se han resuelto incidentes',
  }),
  sendAlert: defineContent({
    default: 'Send alert',
    'de-DE': 'Warnmeldungen senden',
    'es-ES': 'Enviar alerta',
  }),
  location: defineContent({
    default: 'Location',
    'de-DE': 'Ort',
    'es-ES': 'Ubicación',
  }),
  description: defineContent({
    default: 'Description',
    'de-DE': 'Beschreibung',
    'es-ES': 'Descripción',
  }),
  summary: defineContent({
    default: 'Summary',
    'de-DE': 'Zusammenfassung',
    'es-ES': 'Resumen',
  }),
  viewAlerts: defineContent({
    default: 'View alerts',
    'de-DE': 'Warnmeldungen anzeigen',
    'es-ES': 'Ver alertas',
  }),
  actionUnresolveIncident: defineContent({
    default: 'This action will unresolve the incident.',
    'de-DE': 'Diese Aktion wird den Vorfall nicht lösen.',
    'es-ES': 'Esta acción no resolverá el incidente.',
  }),
  actionResolveIncident: defineContent({
    default: 'This action will resolve the incident.',
    'de-DE': 'Diese Aktion wird den Vorfall lösen.',
    'es-ES': 'Esta acción resolverá el incidente.',
  }),
  actionDeleteIncident: defineContent({
    default:
      'This action will permanently delete the incident. This cannot be undone.',
    'de-DE':
      'Diese Aktion löscht den Vorfall dauerhaft. Dies kann nicht rückgängig gemacht werden.',
    'es-ES':
      'Esta acción eliminará permanentemente el incidente. Esto no se puede deshacer.',
  }),
  deleteIncident: defineContent({
    default: 'Delete incident',
    'de-DE': 'Vorfall löschen',
    'es-ES': 'Eliminar incidente',
  }),
  editIncident: defineContent({
    default: 'Edit incident',
    'de-DE': 'Vorfall bearbeiten',
    'es-ES': 'Editar incidente',
  }),
  provideTitleForIncident: defineContent({
    default: 'Please provide a title for the incident',
    'de-DE': 'Bitte geben Sie einen Titel für den Vorfall an',
    'es-ES': 'Por favor proporcione un título para el incidente',
  }),
  whenDidIncidentOccur: defineContent({
    default: 'When did the incident occur?',
    'de-DE': 'Wann ist der Vorfall aufgetreten?',
    'es-ES': '¿Cuándo ocurrió el incidente?',
  }),
  pickADate: defineContent({
    default: 'Pick a date',
    'de-DE': 'Wählen Sie ein Datum',
    'es-ES': 'Elige una fecha',
  }),
  whereDidIncidentOccur: defineContent({
    default: 'Where did the incident occur?',
    'de-DE': 'Wo ist der Vorfall aufgetreten?',
    'es-ES': '¿Dónde ocurrió el incidente?',
  }),
  locationOfIncident: defineContent({
    default: 'Location of incident',
    'de-DE': 'Ort des Vorfalls',
    'es-ES': 'Ubicación del incidente',
  }),
  describeIncident: defineContent({
    default: 'Describe the incident',
    'de-DE': 'Beschreiben Sie den Vorfall',
    'es-ES': 'Describe el incidente',
  }),
  sendAlertToContacts: defineContent({
    default: 'Send incident alert to contacts',
    'de-DE': 'Warnmeldung an Mitglieder senden',
    'es-ES': 'Enviar alerta de incidente a los miembros',
  }),
  thisWillSendAlert: defineContent({
    default:
      'This will send an alert to all contacts of this org. Are you sure you want to proceed?',
    'de-DE':
      'Dies sendet eine Warnmeldung an alle Mitglieder dieser Gruppe. Möchten Sie fortfahren?',
    'es-ES':
      'Esto enviará una alerta a todos los miembros de este grupo. ¿Estás seguro de que quieres continuar?',
  }),
  sendAlertToXContacts: defineContent({
    default: 'Send alert to {contactsTotal} contact{s}',
    'de-DE': 'Warnmeldung an {contactsTotal} Mitglied{e}',
    'es-ES': 'Enviar alerta a {contactsTotal} miembro{s}',
  }),
};
