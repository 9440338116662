export const LogoIcon = () => {
  return (
    <svg
      className="w-8 text-foreground md:w-10"
      viewBox="0 0 280 280"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="7.5"
        y="7.5"
        width="265"
        height="265"
        rx="42.5"
        stroke="currentColor"
        strokeWidth="15"
      />
      <path
        d="M65.9066 177V104.1H128.707V120H86.1066V132.9H123.707V147.7H86.1066V161.1H129.007V177H65.9066Z"
        fill="currentColor"
      />
      <path
        d="M146.61 177V104.1H190.31C195.576 104.1 200.376 104.8 204.71 106.2C209.043 107.533 212.476 109.8 215.01 113C217.61 116.2 218.91 120.567 218.91 126.1C218.91 130.433 217.743 134.233 215.41 137.5C213.076 140.7 209.776 142.833 205.51 143.9V144.6C209.376 145.267 212.243 146.767 214.11 149.1C216.043 151.433 217.01 154.7 217.01 158.9V168.2C217.01 169.6 217.043 171.067 217.11 172.6C217.243 174.067 217.643 175.533 218.31 177H198.01C197.21 175.333 196.81 172.433 196.81 168.3V161.6C196.81 158.8 196.11 156.6 194.71 155C193.376 153.333 190.676 152.5 186.61 152.5H166.81V177H146.61ZM166.81 138.1H186.31C190.976 138.1 194.143 137.2 195.81 135.4C197.543 133.6 198.41 131.233 198.41 128.3C198.41 124.833 197.276 122.467 195.01 121.2C192.81 119.933 189.91 119.3 186.31 119.3H166.81V138.1Z"
        fill="currentColor"
      />
    </svg>
  );
};
