import {
  createUserWithEmailAndPassword,
  signOut as firebaseSignout,
  getAuth as getFirebaseAuth,
  signInWithEmailAndPassword,
  updateProfile,
} from 'firebase/auth';
import { firebase } from '~/firebase';
import { isNotProduction } from '~/utils';

export const getAuth = () => {
  return getFirebaseAuth(firebase);
};

export const auth = getAuth();

if (isNotProduction()) {
  // connectAuthEmulator(auth, 'http://localhost:9099');
}

export const getUser = () => {
  return auth.currentUser;
};

export const signIn = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  await signInWithEmailAndPassword(auth, email, password);
};

export const signUp = async ({
  name,
  email,
  password,
}: {
  name: string;
  email: string;
  password: string;
}) => {
  const user = await createUserWithEmailAndPassword(auth, email, password);

  await updateProfile(user.user, {
    displayName: name,
  });
};

export const signOut = async () => {
  await firebaseSignout(auth);
};
