import { useEffect, useState } from 'react';
import { Separator } from '~/components';
import { useContent } from '~/content/hooks';
import { db, OrgContactInvite } from '~/db';
import { getOrgId } from '~/org';
import { numberWithCommas } from '~/utils';

export const ContactInvites = ({
  showSeparator,
}: {
  showSeparator?: boolean;
}) => {
  const content = useContent();
  const [invites, setInvites] = useState<OrgContactInvite[]>([]);

  useEffect(() => {
    const orgId = getOrgId();
    const unsubscribe = db.orgContactInvite.subscribeList({
      data: {
        orgId,
      },
      onUpdate: setInvites,
    });

    return () => {
      unsubscribe();
    };
  }, []);

  if (!invites.length) return null;

  return (
    <>
      {showSeparator && <Separator className="my-10 md:my-20" />}

      <div className="mx-auto w-full max-w-4xl space-y-6">
        <div className="flex flex-col gap-6 rounded-lg border p-6 md:flex-row md:items-center">
          <h2 className="text-lg font-bold">
            {content.get('invites')} ({numberWithCommas(invites.length)})
          </h2>
        </div>

        <div className="mx-auto w-full max-w-4xl overflow-hidden rounded-lg border">
          {invites.map((invite, inviteIndex) => {
            return (
              <div key={invite.id}>
                {inviteIndex !== 0 && <Separator />}

                <div className="flex flex-col gap-2 px-8 py-6 lg:flex-row lg:gap-14 lg:py-6 xl:py-8">
                  <div className="flex items-center gap-4">
                    <div className="font-semibold">{invite.email}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
