import { createUniqueId, firestore } from '~/firebase';
import { getUnix } from '~/utils';

export const createClient = <T extends { id: string }>({
  path,
}: {
  path: string;
}) => {
  return {
    create: async ({ data }: { data: Partial<T> }) => {
      const id = data.id ?? createUniqueId();

      await firestore.set({
        path,
        docId: id,
        data: {
          id,
          created: getUnix(),
          updated: getUnix(),
          ...data,
        },
      });

      const doc = await firestore.get<T>({
        path,
        docId: id,
        data,
      });

      if (!doc) throw new Error('Failed to create document');

      return doc;
    },

    get: async (id: string, data?: any) => {
      return firestore.get<T>({
        path,
        docId: id,
        data,
      });
    },

    list: async (options?: {
      data?: Partial<T>;
      orderByKey?: string;
      orderByValue?: 'asc' | 'desc';
    }) => {
      return firestore.list<T>({
        path,
        orderByKey: options?.orderByKey,
        orderByValue: options?.orderByValue,
        data: options?.data,
      });
    },

    first: async (data?: Partial<T>) => {
      const list = await firestore.list<T | null>({
        path,
        data,
      });

      return list[0];
    },

    update: async ({ id, data }: { id: string; data: Partial<T> }) => {
      await firestore.set({
        path,
        docId: id,
        data: {
          updated: getUnix(),
          ...data,
        },
      });
    },

    subscribe: ({
      id,
      data,
      onUpdate,
    }: {
      id: string;
      data?: any;
      onUpdate: (data: T) => void;
    }) => {
      return firestore.subscribe<T>({
        path,
        docId: id,
        data,
        onUpdate,
      });
    },

    subscribeList: ({
      orderByKey,
      orderByValue,
      where,
      limit,
      data,
      onUpdate,
    }: {
      orderByKey?: string;
      orderByValue?: 'asc' | 'desc';
      where?: {
        key: string;
        operator: '==' | '<' | '<=' | '>' | '>=';
        value: any;
      };
      limit?: number;
      data?: Partial<T>;
      onUpdate: (data: T[]) => void;
    }) => {
      return firestore.subscribeList<T>({
        path,
        orderByKey,
        orderByValue,
        where,
        limit,
        data,
        onUpdate,
      });
    },

    delete: ({ id, data }: { id: string; data?: Partial<T> }) => {
      return firestore.delete({
        path,
        docId: id,
        data,
      });
    },
  };
};
