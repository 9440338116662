import { useMutation } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { analytics } from '~/analytics';
import { acceptContactInvite, getContactInvite } from '~/api';
import { Button } from '~/components';
import { useContent } from '~/content/hooks';
import { ContactInviteCode, Org } from '~/db';
import { setOrgCookie } from '~/org';
import { sentry } from '~/sentry';
import { useParams } from '~/utils/hooks';

let loaded = false;

export const ContactInvite = () => {
  const { code } = useParams<{ code: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [invite, setInvite] = useState<ContactInviteCode | null>(null);
  const [org, setOrg] = useState<Org | null>(null);
  const content = useContent();

  const accept = useMutation({
    mutationFn: async () => {
      const { org } = await acceptContactInvite({ code });

      if (!org) {
        setInvite(null);
        setOrg(null);

        return;
      }

      setOrgCookie(org.id);

      analytics.track('contact.invite.accept');

      window.location.href = '/';
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  useEffect(() => {
    if (loaded) return;

    loaded = true;

    const fn = async () => {
      const { invite, org } = await getContactInvite({ code });

      if (invite) {
        setInvite(invite);
      }

      if (org) {
        setOrg(org);
      }

      setIsLoading(false);
    };

    fn();
  }, [code]);

  if (isLoading) {
    return (
      <div className="fixed left-0 top-0 flex h-screen w-screen items-center justify-center">
        <div className="h-10 w-10">
          <div className="loader" />
        </div>
      </div>
    );
  }

  if (!invite || !org) {
    return (
      <div className="fixed left-0 top-0 flex h-screen w-screen items-center justify-center">
        <div className="space-y-4 text-center">
          <h1 className="text-lg font-bold md:text-2xl">
            {content.get('invalidCode')}
          </h1>

          <p className="text-muted-foreground">
            {content.get('thisCodeInvalid')}
          </p>

          <div className="pt-8">
            <Link to="/">
              <Button size="sm">{content.get('done')}</Button>
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="py-10 md:py-20">
      <div
        className="mx-auto max-w-md rounded-lg border p-6 text-center"
        key={invite.id}
      >
        <p className="text-balance text-lg text-muted-foreground">
          {content.get('youveBeenInvited')}
        </p>

        <div className="mt-4 text-balance text-xl font-semibold md:text-2xl lg:text-3xl">
          {org.name}
        </div>

        <Button
          className="mt-16 w-full"
          type="submit"
          size="md"
          disabled={accept.isPending}
          onClick={() => accept.mutate()}
        >
          {accept.isPending ? (
            <Loader2 className="h-6 w-6 animate-spin" />
          ) : (
            content.get('acceptInvite')
          )}
        </Button>
      </div>
    </div>
  );
};
