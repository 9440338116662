import { useEffect, useState } from 'react';
import { AudioPlayer } from '~/audio/components';
import { Separator } from '~/components';
import { db } from '~/db';
import { getDownloadUrl } from '~/firebase';
import { useLanguage } from '~/language/hooks';
import { getOrgId } from '~/org';
import { usePlan } from '~/plan/hooks';

export const IncidentRecording = ({ incidentId }: { incidentId: string }) => {
  const { language } = useLanguage();
  const [url, setUrl] = useState<string | null>(null);
  const { hasEssential } = usePlan();

  useEffect(() => {
    const orgId = getOrgId();
    const unsubscribe = db.incidentRecording.subscribeList({
      data: {
        orgId,
        incidentId,
      },
      orderByKey: 'updated',
      orderByValue: 'desc',
      limit: 1,
      onUpdate: async (recordings) => {
        const recording = recordings[0];

        if (recording?.status?.[language.id] === 'complete') {
          const url = await getDownloadUrl({
            path: `orgs/${orgId}/recordings/${recording.id}-${language.id}.mp3`,
          });

          setUrl(url);
        } else {
          setUrl(null);
        }
      },
    });

    return () => {
      unsubscribe();
    };
  }, [incidentId, language.id]);

  if (!hasEssential()) return null;

  if (!url) return null;

  return (
    <>
      <Separator />

      <AudioPlayer className="px-6" src={url} />
    </>
  );
};
