import { useMutation, useQuery } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { Button, Separator } from '~/components';
import { useContent } from '~/content/hooks';
import { db } from '~/db';
import { sendAlert } from '~/incident';
import { IncidentProvider } from '~/incident/components';
import { useIncident } from '~/incident/hooks';
import { getOrgId } from '~/org';
import { sentry } from '~/sentry';
import { formatDateTime } from '~/utils';
import { useParams } from '~/utils/hooks';
import { IncidentContacts } from './IncidentContacts';

export const SendIncidentAlert = () => {
  const { incidentId } = useParams<{ incidentId: string }>();

  return (
    <IncidentProvider incidentId={incidentId}>
      <SendIncidentAlertComponent />
    </IncidentProvider>
  );
};

export const SendIncidentAlertComponent = () => {
  const incident = useIncident();
  const navigate = useNavigate();
  const content = useContent();
  const alertSent = content.get('alertSent');

  const { data: orgContacts } = useQuery({
    queryKey: ['contacts', { orgId: incident.orgId }],
    queryFn: async () => {
      const orgId = getOrgId();
      const orgContacts = await db.orgContact.list({
        data: {
          orgId,
        },
      });

      return orgContacts;
    },
  });

  const sendIncidentAlert = useMutation({
    mutationFn: async () => {
      const alert = await sendAlert(incident.id);

      if (!alert) throw new Error('Failed to send alert');

      return alert;
    },
    onSuccess: (alert) => {
      toast.error(alertSent);

      navigate(`/incidents/${alert.incidentId}/alerts/${alert.id}`);
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  if (!orgContacts) return null;

  return (
    <div className="space-y-20 lg:space-y-24">
      <div className="mx-auto w-full max-w-4xl space-y-6">
        <div className="rounded-lg border">
          <div className="bg-muted/50 px-4 py-3 text-sm font-medium">
            {content.get('sendAlertToContacts')}
          </div>

          <div className="space-y-2 p-6">
            <div className="font-medium text-muted-foreground">
              {formatDateTime(incident.date, 'D MMM YYYY')}
            </div>

            <h1 className="text-2xl font-bold md:text-3xl md:leading-normal">
              {incident.title}
            </h1>

            {/* {incident.latestAlert && (
              <div className="space-y-2 pt-4">
                <div className="font-medium text-muted-foreground">
                  Previous alert sent
                </div>

                <a
                  href={`/incidents/${incident.id}/alerts/${incident.latestAlert.id}`}
                >
                  <p className="mt-2 inline-block hover:underline">
                    {convertUnixToDate(
                      incident.latestAlert.updated,
                      'HH:mm - D MMM YYYY',
                    )}
                  </p>
                </a>
              </div>
            )} */}
          </div>
        </div>

        <div className="space-y-10 rounded-lg border p-6 text-lg">
          <p>{content.get('thisWillSendAlert')}</p>

          <Button
            variant="destructive"
            size="lg"
            className="w-full"
            disabled={sendIncidentAlert.isPending}
            onClick={() => sendIncidentAlert.mutate()}
          >
            {sendIncidentAlert.isPending ? (
              <Loader2 className="h-6 w-6 animate-spin" />
            ) : (
              <>
                {content.get('sendAlertToXContacts', {
                  contactsTotal: orgContacts?.length,
                  s: orgContacts?.length !== 1 ? 's' : '',
                  e: orgContacts?.length !== 1 ? 'e' : '',
                })}
              </>
            )}
          </Button>
        </div>
      </div>

      <Separator />

      <IncidentContacts orgContacts={orgContacts} />
    </div>
  );
};
