import { AnimatePresence, motion } from 'framer-motion';
import { Loader2 } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useAuth, useRedirectToDashboard } from '~/auth/hooks';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Input,
  Separator,
} from '~/components';
import { useContent } from '~/content/hooks';

export const SignUp = () => {
  useRedirectToDashboard();

  const {
    email,
    password,
    name,
    setEmail,
    setPassword,
    setName,
    signUp,
    isSignUpLoading,
    error,
  } = useAuth();
  const content = useContent();

  return (
    <div className="lg:fixed lg:left-0 lg:top-0 lg:flex lg:h-screen lg:w-screen lg:items-center lg:justify-center">
      <div className="mx-auto w-full max-w-lg p-4 sm:p-8 md:max-w-xl md:p-20">
        <Card>
          <CardHeader>
            <CardTitle className="mb-6 text-center text-2xl">
              {content.get('signUp')}
            </CardTitle>
          </CardHeader>

          <CardContent className="grid gap-6">
            <div className="hidden">
              <a href="/sign-in/google">
                <Button className="relative w-full" variant="outline">
                  <svg
                    className="absolute left-6 top-1/2 w-6 -translate-y-1/2"
                    viewBox="0 0 37 37"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M36.03 18.7899C36.0518 17.5257 35.921 16.2615 35.6449 15.0336H18.3818V21.856H28.5174C28.1323 24.2754 26.7736 26.426 24.761 27.821L24.7247 28.0463L30.1812 32.2748L30.559 32.3112C34.0247 29.107 36.03 24.3917 36.03 18.7899Z"
                      fill="#4285F4"
                    />
                    <path
                      d="M18.3819 36.765C23.3443 36.765 27.5148 35.1302 30.5591 32.3112L24.7611 27.8138C22.8793 29.078 20.6488 29.7174 18.3892 29.652C13.623 29.6229 9.40891 26.5423 7.92673 22.0085L7.70876 22.0303L2.03433 26.4188L1.96167 26.6222C5.07135 32.8488 11.4288 36.7723 18.3819 36.765Z"
                      fill="#34A853"
                    />
                    <path
                      d="M7.91951 22.0159C7.5199 20.8461 7.3092 19.6182 7.3092 18.3758C7.31646 17.1407 7.5199 15.9128 7.90498 14.7358L7.89771 14.496L2.15062 10.0349L1.96171 10.1221C-0.653904 15.317 -0.653904 21.4346 1.96171 26.6296L7.91951 22.0159Z"
                      fill="#FBBC05"
                    />
                    <path
                      d="M18.3818 7.10686C21.0192 7.06327 23.5622 8.04413 25.4876 9.846L30.6752 4.78187C27.3476 1.65766 22.9374 -0.0497548 18.3746 0.00110439C11.4214 0.00110439 5.06397 3.91726 1.94702 10.1366L7.89028 14.7503C9.387 10.2165 13.6083 7.13593 18.3818 7.10686Z"
                      fill="#EB4335"
                    />
                  </svg>

                  <span className="pl-6 text-xs sm:pl-0 sm:text-base">
                    {content.get('continueWithGoogle')}
                  </span>
                </Button>
              </a>

              <Separator />
            </div>

            <form
              onSubmit={(event) => {
                event.preventDefault();

                signUp();
              }}
            >
              <div className="grid gap-6">
                <Input
                  placeholder={content.get('name')}
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  required
                />

                <Input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  required
                />

                <Input
                  type="password"
                  placeholder={content.get('password')}
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  required
                />

                <Button type="submit" className="w-full">
                  {isSignUpLoading ? (
                    <Loader2 className="h-6 w-6 animate-spin" />
                  ) : (
                    content.get('signUp')
                  )}
                </Button>

                <div className="h-10 text-center text-destructive">
                  <AnimatePresence>
                    {error && (
                      <motion.div
                        initial={{ opacity: 0, translateY: -10 }}
                        animate={{
                          opacity: 1,
                          translateY: 0,
                        }}
                        exit={{ opacity: 0, translateY: -10 }}
                      >
                        {error}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>

                <div className="mt-20 text-center text-sm">
                  {content.get('alreadyHaveAnAccount')} &nbsp;&nbsp;
                  <Link to="/sign-in" className="font-semibold text-primary">
                    {content.get('signIn')}
                  </Link>
                </div>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
