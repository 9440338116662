import {
  deleteDoc,
  doc,
  collection as firebaseCollection,
  collection as firestoreCollection,
  limit as firestoreLimit,
  where as firestoreWhere,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  setDoc,
} from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { firebase, formatPath, getUser } from '~/firebase';
import { isNotProduction } from '~/utils';

export const database = getFirestore(firebase);

if (isNotProduction()) {
  // connectFirestoreEmulator(database, 'localhost', 8080);
}

export const firestore = {
  get: async <T>({
    path,
    docId,
    data,
  }: {
    path: string;
    docId: string;
    data?: any;
  }) => {
    const user = getUser();

    if (!user) throw new Error('User not found');

    const formattedPath = formatPath(user.uid, path, data);
    const docRef = doc(database, formattedPath, docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data() as T;
    } else {
      return null;
    }
  },

  list: async <T>({
    path,
    data,
    orderByKey,
    orderByValue,
  }: {
    path: string;
    data?: any;
    orderByKey?: string;
    orderByValue?: 'asc' | 'desc';
  }): Promise<T[]> => {
    const user = getUser();

    if (!user) throw new Error('User not found');

    const formattedPath = formatPath(user.uid, path, data);
    const collectionRef = firestoreCollection(database, formattedPath);

    const q = query(
      collectionRef,
      orderBy(orderByKey ?? 'created', orderByValue ?? 'asc'),
    );
    const querySnapshot = await getDocs(q);

    const docs: T[] = querySnapshot.docs.map((doc) => doc.data() as T);

    return docs;
  },

  set: async <T>({
    path,
    docId,
    data,
  }: {
    path: string;
    docId: string;
    data: T;
  }) => {
    const user = getUser();

    if (!user) throw new Error('User not found');

    const formattedPath = formatPath(user.uid, path, data);

    await setDoc(doc(database, formattedPath, docId), data as any, {
      merge: true,
    });
  },

  subscribe: <T>({
    path,
    docId,
    data,
    onUpdate,
  }: {
    path: string;
    docId: string;
    data?: any;
    onUpdate: (data: T) => void;
  }) => {
    const user = getUser();

    if (!user) throw new Error('User not found');

    const formattedPath = formatPath(user.uid, path, data);
    const docRef = doc(database, formattedPath, docId);

    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        onUpdate(docSnap.data() as T);
      }
    });

    return unsubscribe;
  },

  subscribeList: <T>({
    path,
    orderByKey,
    orderByValue,
    where,
    limit,
    data,
    onUpdate,
  }: {
    path: string;
    orderByKey?: string;
    orderByValue?: 'asc' | 'desc';
    where?: {
      key: string;
      operator: '==' | '<' | '<=' | '>' | '>=';
      value: any;
    };
    limit?: number;
    data?: any;
    onUpdate: (data: T[]) => void;
  }) => {
    const user = getUser();

    if (!user) throw new Error('User not found');

    const formattedPath = formatPath(user.uid, path, data);
    const q = where
      ? query(
          firebaseCollection(database, formattedPath),
          orderBy(orderByKey ?? 'created', orderByValue ?? 'asc'),
          firestoreWhere(where.key, where.operator, where.value),
          firestoreLimit(limit ?? 20),
        )
      : query(
          firebaseCollection(database, formattedPath),
          orderBy(orderByKey ?? 'created', orderByValue ?? 'asc'),
          firestoreLimit(limit ?? 20),
        );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data: any[] = [];

      querySnapshot.forEach((doc) => {
        data.push(doc.data());
      });

      onUpdate(data);
    });

    return unsubscribe;
  },

  delete: async ({
    path,
    docId,
    data,
  }: {
    path: string;
    docId: string;
    data?: any;
  }) => {
    const user = getUser();

    if (!user) throw new Error('User not found');

    const formattedPath = formatPath(user.uid, path, data);

    await deleteDoc(doc(database, formattedPath, docId));
  },
};

export const createUniqueId = () => {
  return uuidv4();
};
