import action from '~/action/content';
import alert from '~/alert/content';
import auth from '~/auth/content';
import contact from '~/contact/content';
import incident from '~/incident/content';
import { Language } from '~/language';
import org from '~/org/content';
import update from '~/update/content';
import { defineContent } from './define';
import { ContentDefinitions } from './types';

export const content = {
  ...action,
  ...alert,
  ...auth,
  ...incident,
  ...contact,
  ...org,
  ...update,

  date: defineContent({
    default: 'Date',
    'de-DE': 'Datum',
    'es-ES': 'Fecha',
  }),
  edit: defineContent({
    default: 'Edit',
    'de-DE': 'Bearbeiten',
    'es-ES': 'Editar',
  }),
  resolve: defineContent({
    default: 'Resolve',
    'de-DE': 'Lösen',
    'es-ES': 'Resolver',
  }),
  unresolve: defineContent({
    default: 'Unresolve',
    'de-DE': 'Zurücksetzen',
    'es-ES': 'Anular resolución',
  }),
  resolved: defineContent({
    default: 'Resolved',
    'de-DE': 'Gelöst',
    'es-ES': 'Resuelto',
  }),
  areYouSure: defineContent({
    default: 'Are you sure?',
    'de-DE': 'Bist du sicher?',
    'es-ES': '¿Estás seguro?',
  }),
  cancel: defineContent({
    default: 'Cancel',
    'de-DE': 'Abbrechen',
    'es-ES': 'Cancelar',
  }),
  save: defineContent({
    default: 'Save',
    'de-DE': 'Speichern',
    'es-ES': 'Guardar',
  }),
  delete: defineContent({
    default: 'Delete',
    'de-DE': 'Löschen',
    'es-ES': 'Eliminar',
  }),
  sent: defineContent({
    default: 'Sent',
    'de-DE': 'Gesendet',
    'es-ES': 'Enviado',
  }),
  sending: defineContent({
    default: 'Sending',
    'de-DE': 'Senden',
    'es-ES': 'Enviando',
  }),
  status: defineContent({
    default: 'Status',
    'de-DE': 'Status',
    'es-ES': 'Estado',
  }),
  you: defineContent({
    default: 'You',
    'de-DE': 'Du',
    'es-ES': 'Tú',
  }),
  name: defineContent({
    default: 'Name',
    'de-DE': 'Name',
    'es-ES': 'Nombre',
  }),
  phone: defineContent({
    default: 'Phone',
    'de-DE': 'Telefon',
    'es-ES': 'Teléfono',
  }),
  enterPhone: defineContent({
    default: 'Enter phone number',
    'de-DE': 'Telefonnummer eingeben',
    'es-ES': 'Ingrese número de teléfono',
  }),
  done: defineContent({
    default: 'Done',
    'de-DE': 'Fertig',
    'es-ES': 'Hecho',
  }),
};

export const getContent = <K extends keyof ContentDefinitions>(
  key: K,
  language: Language,
) => {
  const item = content[key];

  return (item[language.id] ||
    item.default) as ContentDefinitions[K]['default'];
};
