import { useMutation } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import { getPayUrl } from '~/api';
import { getAccountId } from '~/auth';
import { Button } from '~/components';
import type { Plan } from '~/plan';
import { sentry } from '~/sentry';
import { cn } from '~/style';

export const PlanCard = ({ plan }: { plan: Plan }) => {
  const mutation = useMutation({
    mutationFn: getPayUrl,
    onSuccess: ({ url }) => {
      window.location.href = url;
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  return (
    <div className="relative">
      {plan.isPopular && (
        <div className="absolute left-1/2 top-0 z-10 -translate-x-1/2 -translate-y-1/2 rounded-full bg-primary px-3 py-1 text-xs font-bold uppercase text-background">
          Popular
        </div>
      )}

      <div
        className={cn(
          'border-neutral relative mx-auto w-full max-w-sm space-y-6 rounded-xl border bg-background p-6 lg:p-8',
          { 'border-primary': plan.isPopular },
        )}
      >
        <div className="text-lg font-black md:text-xl">{plan.name}</div>

        <div className="relative flex items-center gap-3">
          <div className="text-3xl font-black lg:text-4xl">
            {plan.priceText}
          </div>

          {plan.description && (
            <div className="absolute top-full mt-4 text-muted-foreground">
              {plan.description}
            </div>
          )}
        </div>

        <div className="h-96 space-y-4 pt-6">
          {plan.description && <div className="pt-5" />}

          {plan.features.map((feature) => (
            <div className="flex items-center gap-4" key={`feature-${feature}`}>
              <svg
                className="text-success size-6 shrink-0"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>

              <span className="text-sm text-muted-foreground">{feature}</span>
            </div>
          ))}

          {plan.notIncludedFeatures?.map((feature) => (
            <div
              className="flex items-center gap-4 opacity-50"
              key={`not-included-feature-${feature}`}
            >
              <svg
                className="size-6 shrink-0 text-muted-foreground"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>

              <span className="text-sm text-muted-foreground">{feature}</span>
            </div>
          ))}
        </div>

        <div className="pt-10">
          {plan.id === 'custom' ? (
            <a href="https://calendly.com/contential/15" target="_blank">
              <Button className="w-full" variant="outline-strong">
                Contact sales
              </Button>
            </a>
          ) : (
            <Button
              className="w-full"
              variant={plan.isPopular ? 'default' : 'outline-strong'}
              onClick={() => {
                const accountId = getAccountId();

                mutation.mutate({ accountId, planId: plan.id });
              }}
            >
              {mutation.isPending ? (
                <Loader2 className="h-6 w-6 animate-spin" />
              ) : (
                'Choose plan'
              )}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
