import { useMutation, useQuery } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { generateIncidentSummary } from '~/api';
import { useAuth } from '~/auth/hooks';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  OriginalButton,
  Separator,
  Textarea,
} from '~/components';
import { useContent } from '~/content/hooks';
import { db, Update } from '~/db';
import { useLanguage } from '~/language/hooks';
import { getOrgId } from '~/org';
import { sentry } from '~/sentry';
import { getUpdateData } from '~/update';
import { useParams } from '~/utils/hooks';

export const EditUpdate = () => {
  const { incidentId, updateId } = useParams<{
    incidentId: string;
    updateId: string;
  }>();
  const orgId = getOrgId();
  const { data: update } = useQuery({
    queryKey: ['update', updateId],
    queryFn: async () => {
      const update = await db.update.get(updateId, {
        orgId,
        incidentId,
      });

      return update;
    },
  });

  if (!update) return null;

  return <EditUpdatePage update={update} />;
};

export const EditUpdatePage = ({ update }: { update: Update }) => {
  const { language } = useLanguage();
  const [text, setText] = useState(getUpdateData(update, language, 'text'));
  const { user } = useAuth();
  const navigate = useNavigate();
  const content = useContent();

  const updateMutation = useMutation({
    mutationFn: async ({ text }: { text: string }) => {
      await db.update.update({
        id: update.id,
        data: {
          orgId: update.orgId,
          incidentId: update.incidentId,
          text,
        },
      });

      await generateIncidentSummary({
        orgId: update.orgId,
        incidentId: update.incidentId,
      });
    },
    onSuccess: async () => {
      navigate(`/incidents/${update.incidentId}`);
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  const remove = useMutation({
    mutationFn: async () => {
      await db.update.delete({
        id: update.id,
        data: {
          orgId: update.orgId,
          incidentId: update.incidentId,
        },
      });

      await generateIncidentSummary({
        orgId: update.orgId,
        incidentId: update.incidentId,
      });
    },
    onSuccess: async () => {
      navigate(`/incidents/${update.incidentId}`);
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  const isCurrentUser = user?.uid === update.userId;

  if (isCurrentUser) {
    return (
      <div className="mx-auto w-full max-w-2xl space-y-10 md:space-y-14">
        <h1 className="text-left text-2xl font-bold md:text-3xl">
          {content.get('editUpdate')}
        </h1>

        <form
          className="space-y-6 md:space-y-10"
          onSubmit={(event) => {
            event.preventDefault();

            if (!text) return;

            updateMutation.mutate({
              text,
            });
          }}
        >
          <div className="rounded-lg border">
            <div className="bg-muted/50 px-6 py-4">
              {content.get('whatIsUpdate')}
            </div>

            <Separator />

            <div className="p-6">
              <Textarea
                value={text}
                onChange={(event) => setText(event.target.value)}
                placeholder={`${content.get('enterUpdate')}...`}
                rows={10}
              />
            </div>
          </div>

          <Button
            className="w-full"
            type="submit"
            disabled={updateMutation.isPending || !text}
          >
            {updateMutation.isPending ? (
              <Loader2 className="h-6 w-6 animate-spin" />
            ) : (
              content.get('save')
            )}
          </Button>
        </form>

        <div className="text-center">
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button variant="outline" size="sm" disabled={remove.isPending}>
                {remove.isPending ? (
                  <Loader2 className="h-6 w-6 animate-spin" />
                ) : (
                  content.get('delete')
                )}
              </Button>
            </AlertDialogTrigger>

            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>{content.get('areYouSure')}</AlertDialogTitle>

                <AlertDialogDescription className="pb-6">
                  {content.get('deleteUpdatePermanently')}
                </AlertDialogDescription>
              </AlertDialogHeader>

              <AlertDialogFooter>
                <AlertDialogCancel>{content.get('cancel')}</AlertDialogCancel>

                <AlertDialogAction asChild>
                  <OriginalButton onClick={() => remove.mutate()}>
                    {content.get('deleteUpdate')}
                  </OriginalButton>
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>

        <input
          id="hidden"
          className="pointer-events-none fixed left-full top-full -z-50 h-0 w-0 opacity-0"
          type="text"
        />
      </div>
    );
  }

  return (
    <div className="mx-auto w-full max-w-2xl flex-col gap-6 rounded-lg border p-6 md:p-8 lg:p-12">
      <div>{update.text} </div>
    </div>
  );
};
