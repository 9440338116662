import { useMutation, useQuery } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { analytics } from '~/analytics';
import { updateContact } from '~/api';
import { Badge, Button, Input, Separator } from '~/components';
import { useContent } from '~/content/hooks';
import { db, Profile } from '~/db';
import { getOrgId } from '~/org';
import { usePlan } from '~/plan/hooks';
import { sentry } from '~/sentry';
import { useParams } from '~/utils/hooks';

export const EditContact = () => {
  const orgId = getOrgId();
  const { contactId } = useParams<{ contactId: string }>();
  const { data: contact } = useQuery({
    queryKey: ['contact', contactId],
    queryFn: async () => {
      const orgContact = await db.orgContact.get(contactId, {
        orgId,
        contactId,
      });

      return orgContact?.contact;
    },
  });

  if (!contact) return null;

  return <EditContactComponent contact={contact} />;
};

export const EditContactComponent = ({ contact }: { contact: Profile }) => {
  const [name, setName] = useState(contact.name);
  const [phone, setPhone] = useState(contact.phone || '');
  const navigate = useNavigate();
  const content = useContent();
  const { hasEssential } = usePlan();

  const update = useMutation({
    mutationFn: async (input: { name: string; phone: string }) => {
      const orgId = getOrgId();

      await updateContact({
        orgId,
        contactId: contact.id,
        ...input,
      });
    },
    onSuccess: () => {
      analytics.track('contact.update');

      navigate(`/contacts/${contact.id}`);
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  return (
    <div className="mx-auto w-full max-w-xl space-y-6 md:space-y-10">
      <div className="w-full space-y-2 rounded-lg border p-6">
        <div className="flex w-full items-center gap-4">
          <h1 className="text-2xl font-bold">{contact.name}</h1>

          <div>
            <Badge variant="secondary">{content.get('you')}</Badge>
          </div>
        </div>

        <div className="text-muted-foreground">{contact.email}</div>
      </div>

      <form
        className="space-y-6 md:space-y-10"
        onSubmit={(event) => {
          event.preventDefault();

          update.mutate({
            name: name.trim(),
            phone: phone.trim(),
          });
        }}
      >
        <div className="rounded-lg border">
          <div className="bg-muted/50 px-6 py-4">{content.get('name')}</div>

          <Separator />

          <div className="p-6">
            <Input
              value={name}
              onChange={(event) => setName(event.target.value)}
              placeholder={content.get('name')}
            />
          </div>
        </div>

        {hasEssential() && (
          <div className="rounded-lg border">
            <div className="bg-muted/50 px-6 py-4">
              {content.get('enterPhone')}
            </div>

            <Separator />

            <div className="p-6">
              <Input
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
                placeholder={content.get('phone')}
              />
            </div>
          </div>
        )}

        <Button className="w-full" type="submit" disabled={update.isPending}>
          {update.isPending ? (
            <Loader2 className="h-6 w-6 animate-spin" />
          ) : (
            content.get('save')
          )}
        </Button>
      </form>
    </div>
  );
};
