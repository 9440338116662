export let count = 1;

export const getNextCount = () => {
  return count++;
};

export const getRandomNumber = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const getNumberFromString = (string: string) => {
  try {
    const numberString = string.match(/\d+/g)?.join('');

    if (!numberString) return 0;

    return Number(numberString);
  } catch (error) {
    return 0;
  }
};

export const numberWithCommas = (value: number, decimal?: number) => {
  return (decimal ? value.toFixed(decimal) : value)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
