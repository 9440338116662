import { CreateOrg } from '~/org/components';
import { useOrg } from '~/org/hooks';

export const OrgGuard = ({ children }: { children: React.ReactNode }) => {
  const { isReady, hasOrg } = useOrg();

  if (!isReady) return null;

  if (!hasOrg()) {
    return (
      <>
        <CreateOrg />
      </>
    );
  }

  return children;
};
