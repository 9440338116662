import { useEffect } from 'react';
import { useAuth } from '~/auth/hooks';

export const SignOut = () => {
  const { signOut } = useAuth();

  useEffect(() => {
    signOut();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};
