import { ProtectedPage } from '~/auth/components';
import { Toaster } from '~/components';
import { OrgGuard } from '~/org/components';
import { PlanGuard } from '~/plan/components';
import { cn } from '~/style';
import { DashboardHeader } from './DashboardHeader';

export const DashboardPage = ({ children }: { children: React.ReactNode }) => {
  return (
    <ProtectedPage>
      <OrgGuard>
        <PlanGuard>
          <DashboardHeader />

          <main
            className={cn(
              'min-h-screen px-6 py-28 pt-40 sm:pt-28 md:px-20 md:py-40',
            )}
          >
            {children}

            <Toaster />
          </main>
        </PlanGuard>
      </OrgGuard>
    </ProtectedPage>
  );
};
