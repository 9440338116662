import React, { useEffect, useRef } from 'react';

export const ClickOutside = ({
  className,
  children,
  onClickOutside,
}: {
  className?: string;
  children: React.ReactNode;
  onClickOutside: () => void;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClickOutside]);

  return (
    <div className={className} ref={ref}>
      {children}
    </div>
  );
};
