import { Link } from 'react-router-dom';
import { useAuth } from '~/auth/hooks';
import { Badge, Button, Separator } from '~/components';
import { useContent } from '~/content/hooks';
import { OrgContact } from '~/db';
import { numberWithCommas } from '~/utils';

export const IncidentContacts = ({
  orgContacts,
}: {
  orgContacts: OrgContact[];
}) => {
  const { user } = useAuth();
  const userId = user?.uid;
  const content = useContent();

  return (
    <div className="mx-auto w-full max-w-4xl space-y-6">
      <div className="flex flex-col gap-6 rounded-lg border p-6 md:flex-row md:items-center">
        <h1 className="text-2xl font-bold">
          {content.get('contacts')} ({numberWithCommas(orgContacts.length)})
        </h1>

        <Separator className="md:hidden" />

        <Link className="md:ml-auto" to="/contacts/new">
          <Button className="w-full">{content.get('addContact')}</Button>
        </Link>
      </div>

      {orgContacts.length > 0 && (
        <div className="mx-auto w-full max-w-4xl overflow-hidden rounded-lg border">
          {orgContacts.map((orgContact, orgContactIndex) => {
            const isCurrentUser = orgContact.contact.userId === userId;

            return (
              <div className="hover:bg-muted/40" key={orgContact.contact.id}>
                {orgContactIndex !== 0 && <Separator />}

                <Link to={`/contacts/${orgContact.contact.id}`}>
                  <div className="flex flex-col gap-2 px-8 py-6 lg:flex-row lg:gap-14 lg:py-6 xl:py-8">
                    <div className="flex gap-4">
                      <div className="font-semibold">
                        {orgContact.contact.name}
                      </div>

                      {isCurrentUser && (
                        <Badge variant="secondary">{content.get('you')}</Badge>
                      )}
                    </div>

                    <div className="text-muted-foreground">
                      {orgContact.contact.email}
                    </div>

                    <div className="text-muted-foreground lg:ml-auto">
                      {orgContact.contact.phone}
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
