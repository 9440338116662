import { getUser } from '~/firebase';

export const getAnalyticsUser = () => {
  const user = getUser();

  if (!user) return null;

  return {
    id: user.uid,
    name: user.displayName,
    email: user.email,
  };
};
