import { createClient } from './client';
import type {
  Account,
  AccountUser,
  Action,
  Alert,
  ContactAlert,
  ContactInviteCode,
  ContactOrg,
  Device,
  Incident,
  IncidentRecording,
  Org,
  OrgContact,
  OrgContactInvite,
  Profile,
  Test,
  Update,
} from './types';

export const db = {
  account: createClient<Account>({
    path: 'accounts',
  }),

  accountUser: createClient<AccountUser>({
    path: 'accounts/{accountId}/accountUsers',
  }),

  userAccount: createClient<AccountUser>({
    path: 'users/{userId}/userAccounts',
  }),

  profile: createClient<Profile>({
    path: 'profiles',
  }),

  org: createClient<Org>({
    path: 'orgs',
  }),

  orgContact: createClient<OrgContact>({
    path: 'orgs/{orgId}/orgContacts',
  }),

  contactOrg: createClient<ContactOrg>({
    path: 'users/{contactId}/contactOrgs',
  }),

  incident: createClient<Incident>({
    path: 'orgs/{orgId}/incidents',
  }),

  action: createClient<Action>({
    path: 'orgs/{orgId}/actions',
  }),

  contactInviteCode: createClient<ContactInviteCode>({
    path: 'contactInviteCodes',
  }),

  orgContactInvite: createClient<OrgContactInvite>({
    path: 'orgs/{orgId}/orgContactInvites',
  }),

  update: createClient<Update>({
    path: 'orgs/{orgId}/incidents/{incidentId}/updates',
  }),

  alert: createClient<Alert>({
    path: 'orgs/{orgId}/incidents/{incidentId}/alerts',
  }),

  contactAlert: createClient<ContactAlert>({
    path: 'orgs/{orgId}/incidents/{incidentId}/alerts/{alertId}/contactAlerts',
  }),

  incidentRecording: createClient<IncidentRecording>({
    path: 'orgs/{orgId}/incidents/{incidentId}/incidentRecordings',
  }),

  device: createClient<Device>({
    path: 'users/{userId}/devices',
  }),

  test: createClient<Test>({
    path: 'tests',
  }),
};
