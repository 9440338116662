import { defineContent } from '~/content';

export default {
  updates: defineContent({
    default: 'Updates',
    'de-DE': 'Aktualisierungen',
    'es-ES': 'Actualizaciones',
  }),
  addUpdate: defineContent({
    default: 'Add update',
    'de-DE': 'Neue Aktualisierung',
    'es-ES': 'Nueva actualización',
  }),
  editUpdate: defineContent({
    default: 'Edit update',
    'de-DE': 'Aktualisierung bearbeiten',
    'es-ES': 'Editar actualización',
  }),
  deleteUpdate: defineContent({
    default: 'Delete update',
    'de-DE': 'Aktualisierung löschen',
    'es-ES': 'Eliminar actualización',
  }),
  submitUpdate: defineContent({
    default: 'Submit update',
    'de-DE': 'Aktualisierung einreichen',
    'es-ES': 'Enviar actualización',
  }),
  noUpdatesAdded: defineContent({
    default: 'No updates have been added yet',
    'de-DE': 'Es wurden noch keine Aktualisierungen hinzugefügt',
    'es-ES': 'Aún no se han agregado actualizaciones',
  }),
  whatIsUpdate: defineContent({
    default: 'What is the update?',
    'de-DE': 'Was ist die Aktualisierung?',
    'es-ES': '¿Cuál es la actualización?',
  }),
  enterUpdate: defineContent({
    default: 'Enter update here',
    'de-DE': 'Geben Sie hier die Aktualisierung ein',
    'es-ES': 'Ingrese la actualización aquí',
  }),
  deleteUpdatePermanently: defineContent({
    default:
      'This action will permanently delete the update. This cannot be undone.',
    'de-DE':
      'Diese Aktion löscht die Aktualisierung dauerhaft. Dies kann nicht rückgängig gemacht werden.',
    'es-ES':
      'Esta acción eliminará permanentemente la actualización. Esto no se puede deshacer.',
  }),
};
