import { useAuth } from '~/auth/hooks';
import { Button } from '~/components';
import { useContent } from '~/content/hooks';

export const Account = () => {
  const { user } = useAuth();
  const content = useContent();

  if (!user) return null;

  return (
    <div className="mx-auto max-w-4xl rounded-xl border py-14 text-center dark:border-gray-800">
      <div className="text-lg font-bold md:text-xl">{user.displayName}</div>

      <div className="mt-4">{user.email}</div>

      <div className="mt-20">
        <a href="/sign-out">
          <Button className="px-10" variant="outline" size="md">
            {content.get('signOut')}
          </Button>
        </a>
      </div>
    </div>
  );
};
