import { z } from 'zod';
import { createBackgroundFunction, createFunction } from '~/api';
import * as db from '~/db';

// createAccount
export const createAccountSchema = {
  name: 'createAccount',
  input: z.object({
    name: z.string(),
    email: z.string(),
  }),
  output: z.object({
    account: db.account,
  }),
};

export const createAccount = createFunction(createAccountSchema);

// createOrg
export const createOrgSchema = {
  name: 'createOrg',
  input: z.object({
    accountId: z.string(),
    name: z.string(),
  }),
  output: z.object({
    org: db.org,
  }),
};

export const createOrg = createFunction(createOrgSchema);

// getPayUrl
export const getPayUrlSchema = {
  name: 'getPayUrl',
  input: z.object({
    accountId: z.string(),
    planId: z.string(),
  }),
  output: z.object({
    url: z.string(),
  }),
};

export const getPayUrl = createFunction(getPayUrlSchema);

// planCheckout
export const planCheckoutSchema = {
  name: 'planCheckout',
  input: z.object({
    accountId: z.string(),
    sessionId: z.string(),
  }),
  output: z.object({
    success: z.boolean(),
  }),
};

export const planCheckout = createFunction(planCheckoutSchema);

// acceptContactInvite
export const acceptContactInviteSchema = {
  name: 'acceptContactInvite',
  input: z.object({
    code: z.string(),
  }),
  output: z.object({
    org: z.union([db.org, z.null()]),
  }),
};

export const acceptContactInvite = createFunction(acceptContactInviteSchema);

// getContactInvite
export const getContactInviteSchema = {
  name: 'getContactInvite',
  input: z.object({
    code: z.string(),
  }),
  output: z.object({
    invite: z.union([db.contactInviteCode, z.null()]),
    org: z.union([db.org, z.null()]),
  }),
};

export const getContactInvite = createFunction(getContactInviteSchema);

// inviteContact
export const inviteContactSchema = {
  name: 'inviteContact',
  input: z.object({
    orgId: z.string(),
    email: z.string(),
  }),
  output: z.object({}),
};

export const inviteContact = createFunction(inviteContactSchema);

// removeContact
export const removeContactSchema = {
  name: 'removeContact',
  input: z.object({
    orgId: z.string(),
    contactId: z.string(),
  }),
  output: z.object({
    ok: z.boolean(),
  }),
};

export const removeContact = createFunction(removeContactSchema);

// updateContact
export const updateContactSchema = {
  name: 'updateContact',
  input: z.object({
    orgId: z.string(),
    contactId: z.string(),
    phone: z.union([z.string(), z.null()]),
    name: z.string(),
  }),
  output: z.object({
    contact: z.union([db.profile, z.null()]),
  }),
};

export const updateContact = createFunction(updateContactSchema);

// background functions

// generateIncidentSummary
export const generateIncidentSummarySchema = {
  name: 'generateIncidentSummary',
  schemaPath: 'src/incident/schema/generate-incident-summary.ts',
  input: z.object({
    orgId: z.string(),
    incidentId: z.string(),
  }),
} as const;

export const generateIncidentSummary = createBackgroundFunction(generateIncidentSummarySchema);

// generateIncidentRecording
export const generateIncidentRecordingSchema = {
  name: 'generateIncidentRecording',
  schemaPath: 'src/incident/schema/generate-incident-recording.ts',
  input: z.object({
    orgId: z.string(),
    incidentId: z.string(),
    incidentRecordingId: z.string(),
  }),
} as const;

export const generateIncidentRecording = createBackgroundFunction(generateIncidentRecordingSchema);

// processIncidentAlert
export const processIncidentAlertSchema = {
  name: 'processIncidentAlert',
  schemaPath: 'src/incident/schema/process-incident-alert.ts',
  input: z.object({
    orgId: z.string(),
    incidentId: z.string(),
    alertId: z.string(),
  }),
} as const;

export const processIncidentAlert = createBackgroundFunction(processIncidentAlertSchema);
