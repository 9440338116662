import { cookies } from '~/cookie';

export const getOrgId = () => {
  return cookies.get('org') || '';
};

export const setOrgCookie = (orgId: string | null | undefined) => {
  if (orgId) {
    cookies.set('org', orgId);
  } else {
    removeOrgCookie();
  }
};

export const removeOrgCookie = () => {
  cookies.remove('org');
};
