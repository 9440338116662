import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '~/auth/hooks';
import { Button, Separator } from '~/components';
import { useContent } from '~/content/hooks';
import { db, Update } from '~/db';
import { useLanguage } from '~/language/hooks';
import { getOrgId } from '~/org';
import { formatDateTime, numberWithCommas } from '~/utils';
import { useParams } from '~/utils/hooks';
import { getUpdateData } from '../utils';

export const Updates = ({ className }: { className?: string }) => {
  const { incidentId } = useParams<{ incidentId: string }>();
  const { user } = useAuth();
  const { language } = useLanguage();
  const content = useContent();
  const [updates, setUpdates] = useState<Update[]>([]);

  useEffect(() => {
    const orgId = getOrgId();
    const unsubscribe = db.update.subscribeList({
      data: {
        orgId,
        incidentId,
      },
      orderByKey: 'updated',
      orderByValue: 'desc',
      onUpdate: setUpdates,
    });

    return () => {
      unsubscribe();
    };
  }, [incidentId]);

  return (
    <div className={className}>
      <div className="flex flex-col gap-10 md:flex-row">
        <h1 className="text-left text-2xl font-bold md:text-3xl">
          {content.get('updates')} ({numberWithCommas(updates.length)})
        </h1>

        <Link
          className="md:ml-auto"
          to={`/incidents/${incidentId}/updates/new`}
        >
          <Button className="w-full" size="md">
            {content.get('addUpdate')}
          </Button>
        </Link>
      </div>

      {updates.length > 0 ? (
        <div className="mt-10 space-y-6 md:space-y-10">
          {updates.map((update) => {
            const isCurrentUser = update.user.id === user?.uid;

            return (
              <div className="rounded-lg border" key={update.id}>
                <div className="flex flex-col gap-6 p-6 md:flex-row">
                  <div>{formatDateTime(update.updated)}</div>

                  <div className="text-muted-foreground md:ml-auto">
                    {update.user.name}
                  </div>
                </div>

                <Separator />

                <p
                  className="p-6 text-lg leading-relaxed text-muted-foreground"
                  dangerouslySetInnerHTML={{
                    __html:
                      getUpdateData(update, language, 'text')?.replace(
                        /\n/g,
                        '<br />',
                      ) ?? '',
                  }}
                />

                {isCurrentUser && (
                  <>
                    <Separator />

                    <div className="flex flex-col gap-6 p-6 md:flex-row">
                      <a
                        href={`/incidents/${incidentId}/updates/${update.id}/edit`}
                      >
                        <Button className="w-full" variant="outline" size="sm">
                          {content.get('edit')}
                        </Button>
                      </a>
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="mt-10 text-balance rounded-lg border px-6 py-40 text-center text-lg font-medium text-muted-foreground">
          {content.get('noUpdatesAdded')}
        </div>
      )}
    </div>
  );
};
