import { useMutation } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { analytics } from '~/analytics';
import { inviteContact } from '~/api';
import { Button, Input, Separator } from '~/components';
import { useContent } from '~/content/hooks';
import { getOrgId } from '~/org';
import { sentry } from '~/sentry';

export const CreateContact = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const content = useContent();

  const createContact = useMutation({
    mutationFn: async ({ email }: { email: string }) => {
      const orgId = getOrgId();

      await inviteContact({ email, orgId });
    },
    onSuccess: () => {
      analytics.track('contact.invite');

      navigate('/contacts');
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  return (
    <div className="mx-auto w-full max-w-2xl space-y-10 md:space-y-14">
      <h1 className="text-left text-2xl font-bold md:text-3xl">
        {content.get('newContact')}
      </h1>

      <form
        className="space-y-6 md:space-y-10"
        onSubmit={(event) => {
          event.preventDefault();

          if (!email) return;

          createContact.mutate({
            email: email.trim(),
          });
        }}
      >
        <div className="rounded-lg border">
          <div className="bg-muted/50 px-6 py-4">
            {content.get('enterTheirEmail')}
          </div>

          <Separator />

          <div className="p-6">
            <Input
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              placeholder="Email"
              type="email"
            />
          </div>
        </div>

        <Button
          className="w-full"
          type="submit"
          disabled={createContact.isPending || !email}
        >
          {createContact.isPending ? (
            <Loader2 className="h-6 w-6 animate-spin" />
          ) : (
            content.get('inviteContact')
          )}
        </Button>
      </form>
    </div>
  );
};
