import { useEffect } from 'react';
import { setRedirectPath } from '~/auth';
import { useAuth } from '~/auth/hooks';

export const ProtectedPage = ({ children }: { children: React.ReactNode }) => {
  const { isReady, isSignedIn } = useAuth();

  useEffect(() => {
    if (isReady && !isSignedIn) {
      setRedirectPath();

      window.location.href = '/sign-in';
    }
  }, [isReady, isSignedIn]);

  if (!isReady || !isSignedIn) return null;

  return children;
};
