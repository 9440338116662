import { useAuth } from '~/auth/hooks';
import { useOrg } from '~/org/hooks';
import { Plans } from '~/plan/components';

export const PlanGuard = ({ children }: { children: React.ReactNode }) => {
  const { account } = useAuth();
  const { isOwner } = useOrg();

  if (!account) return null;

  if (!isOwner()) return children;

  if (!account.planId) {
    return (
      <div className="min-h-screen px-6 py-28 md:px-20 md:py-40">
        <Plans />
      </div>
    );
  }

  return children;
};
