import axios from 'axios';
import { z } from 'zod';
import { getAuthToken } from '~/auth';
import { isProduction } from '~/utils';

interface CreateFunctionParams<TInputSchema extends z.ZodSchema<any>> {
  name: string;
  input: TInputSchema;
}

export const createBackgroundFunction = <
  TInputSchema extends z.ZodSchema<any>,
>({
  name,
  input,
}: CreateFunctionParams<TInputSchema>) => {
  return async (inputData: z.infer<TInputSchema>): Promise<void> => {
    input.parse(inputData);

    const baseUrl = isProduction()
      ? 'https://api.emergencyreport.co.uk'
      : 'http://localhost:5000';
    const url = `${baseUrl}/background/trigger/${name}`;
    const token = await getAuthToken();
    const result = await axios({
      url,
      method: 'POST',
      headers: {
        'emergencyreport-token': token,
      },
      data: inputData,
    });

    return result.data;
  };
};
