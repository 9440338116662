import { cookies } from '~/cookie';
import type { Language } from '~/language';

export const languages: Record<string, Language> = {
  'en-GB': {
    id: 'en-GB',
    name: 'English',
    englishName: 'English',
    fullEnglishName: 'English',
    flag: 'gb',
  },
  'de-DE': {
    id: 'de-DE',
    name: 'Deutsch',
    englishName: 'German',
    fullEnglishName: 'German (Germany)',
    flag: 'de',
  },
  'es-ES': {
    id: 'es-ES',
    name: 'Español',
    englishName: 'Spanish',
    fullEnglishName: 'Spanish (Spain)',
    flag: 'es',
  },
};

export const getLanguage = (languageId: string | null | undefined) => {
  if (!languageId) return null;

  const language = languages[languageId];

  return language ?? null;
};

export const getLanguageWithDefault = (
  languageId: string | null | undefined,
) => {
  const language = getLanguage(languageId);

  return language ?? defaultLanguage;
};

export const getLanguageIds = () => {
  return Object.keys(languages);
};

export const getUserLanguageId = () => {
  const supportedLanguageIds = getLanguageIds();
  const userLanguageId = navigator.language;

  if (supportedLanguageIds.includes(userLanguageId)) {
    return userLanguageId;
  }

  const languagePrefix = userLanguageId.split('-')[0];
  const defaultMatch = supportedLanguageIds.find((lang) =>
    lang.startsWith(languagePrefix ?? ''),
  );

  return defaultMatch ?? defaultLanguage.id;
};

export const getLanguages = (omit?: string) => {
  return Object.values(languages).filter((language) => language.id !== omit);
};

export const getDefaultLanguage = () => {
  return defaultLanguage;
};

export const getOtherLanguageIds = (languageId: string) => {
  return getLanguageIds().filter((lang) => lang !== languageId);
};

export const defaultLanguage = getLanguage('en-GB')!; // eslint-disable-line

export const getLanguageFromCookie = () => {
  const languageId = cookies.get('language') || defaultLanguage.id;
  const language = getLanguageWithDefault(languageId);

  cookies.set('language', language.id);

  return language;
};
