import { content } from '~/content/content';
import { useLanguage } from '~/language/hooks';
import { ContentDefinitions } from '../types';

export function useContent(): {
  get: (
    key: keyof ContentDefinitions,
    replace?: Record<string, string | number>,
  ) => ContentDefinitions[keyof ContentDefinitions]['default'];
} {
  const { language } = useLanguage();

  const get = <K extends keyof ContentDefinitions>(
    key: K,
    replace?: Record<string, string | number>,
  ) => {
    const item = content[key];

    const value = (item[language.id] ||
      item.default) as ContentDefinitions[K]['default'] as string;

    if (replace) {
      return Object.entries(replace).reduce((acc, [key, value]) => {
        return acc.replace(`{${key}}`, String(value));
      }, value);
    }

    return value;
  };

  return { get };
}
