import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { CalendarIcon, Loader2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { analytics } from '~/analytics';
import { generateIncidentSummary } from '~/api';
import {
  Button,
  Calendar,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Separator,
  Textarea,
} from '~/components';
import { useContent } from '~/content/hooks';
import { db } from '~/db';
import { useIncident, useIncidentData } from '~/incident/hooks';
import { useOrg } from '~/org/hooks';
import { sentry } from '~/sentry';
import { cn } from '~/style';
import {
  convertUnixToDate,
  dateToCurrentUnix,
  formatDate,
  getUnix,
} from '~/utils';
import { useParams } from '~/utils/hooks';
import { IncidentProvider } from './IncidentProvider';

export const EditIncident = () => {
  const { incidentId } = useParams<{ incidentId: string }>();

  return (
    <IncidentProvider incidentId={incidentId}>
      <EditIncidentComponent />
    </IncidentProvider>
  );
};

export const EditIncidentComponent = () => {
  const incident = useIncident();
  const data = useIncidentData(incident);
  const content = useContent();
  const [date, setDate] = useState<Date>(convertUnixToDate(incident.date));
  const [title, setTitle] = useState(data('title'));
  const [location, setLocation] = useState(data('location'));
  const [description, setDescription] = useState(data('description'));
  const navigate = useNavigate();
  const { isOwner } = useOrg();

  useEffect(() => {
    if (!isOwner()) {
      navigate(`/incidents/${incident.id}`);
    }
  }, [incident.id, isOwner, navigate]);

  const update = useMutation({
    mutationFn: async (input: {
      title: string;
      description: string;
      location?: string;
      date: number;
    }) => {
      await db.incident.update({
        id: incident.id,
        data: {
          orgId: incident.orgId,
          ...input,
        },
      });

      await generateIncidentSummary({
        orgId: incident.orgId,
        incidentId: incident.id,
      });
    },
    onSuccess: async () => {
      analytics.track('incident.update');

      navigate(`/incidents/${incident.id}`);
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  return (
    <div className="mx-auto w-full max-w-2xl space-y-10 md:space-y-14">
      <h1 className="text-left text-2xl font-bold md:text-3xl">
        {content.get('editIncident')}
      </h1>

      <form
        className="space-y-6 md:space-y-10"
        onSubmit={(event) => {
          event.preventDefault();

          if (!title || !description) return;
          const currentDate = formatDate(incident.date);
          const newDate = formatDate(getUnix(date));
          const dateHasChanged = currentDate !== newDate;

          update.mutate({
            title,
            description,
            location,
            date: dateHasChanged ? dateToCurrentUnix(date) : incident.date,
          });
        }}
      >
        <div className="rounded-lg border">
          <div className="bg-muted/50 px-6 py-4">
            {content.get('provideTitleForIncident')}
          </div>

          <Separator />

          <div className="p-6">
            <Input
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              placeholder="Title for the incident..."
            />
          </div>
        </div>

        <div className="rounded-lg border">
          <div className="bg-muted/50 px-6 py-4">
            {content.get('whenDidIncidentOccur')}
          </div>

          <Separator />

          <div className="p-6">
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant={'outline'}
                  className={cn(
                    'w-full justify-start text-left font-normal',
                    !date && 'text-muted-foreground',
                  )}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />

                  {date ? (
                    dayjs(date).format('D MMM YYYY')
                  ) : (
                    <span>{content.get('pickADate')}</span>
                  )}
                </Button>
              </PopoverTrigger>

              <PopoverContent className="w-auto p-0">
                <Calendar
                  mode="single"
                  selected={date}
                  onSelect={(event) => {
                    if (!(event instanceof Date)) return;

                    setDate(event);
                    document.getElementById('hidden')?.focus();
                  }}
                />
              </PopoverContent>
            </Popover>
          </div>
        </div>

        <div className="rounded-lg border">
          <div className="bg-muted/50 px-6 py-4">
            {content.get('whereDidIncidentOccur')}
          </div>

          <Separator />

          <div className="p-6">
            <Textarea
              value={location}
              onChange={(event) => setLocation(event.target.value)}
              placeholder={`${content.get('locationOfIncident')}...`}
              rows={4}
            />
          </div>
        </div>

        <div className="rounded-lg border">
          <div className="bg-muted/50 px-6 py-4">
            {content.get('describeIncident')}
          </div>

          <Separator />

          <div className="p-6">
            <Textarea
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              placeholder={`${content.get('description')}...`}
              rows={20}
            />
          </div>
        </div>

        <Button
          className="w-full"
          type="submit"
          disabled={update.isPending || !description}
        >
          {update.isPending ? (
            <Loader2 className="h-6 w-6 animate-spin" />
          ) : (
            content.get('save')
          )}
        </Button>
      </form>

      <input
        id="hidden"
        className="pointer-events-none fixed left-full top-full -z-50 h-0 w-0 opacity-0"
        type="text"
      />
    </div>
  );
};
