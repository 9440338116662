import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { Calendar as CalendarIcon, Loader2 } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { analytics } from '~/analytics';
import { generateIncidentSummary } from '~/api';
import {
  Button,
  Calendar,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Separator,
  Textarea,
} from '~/components';
import { useContent } from '~/content/hooks';
import { db } from '~/db';
import { getUser } from '~/firebase';
import { getOrgId } from '~/org';
import { sentry } from '~/sentry';
import { cn } from '~/style';
import { dateToCurrentUnix } from '~/utils';

export const CreateIncident = () => {
  const [date, setDate] = useState<Date>(new Date());
  const [location, setLocation] = useState('');
  const [description, setDescription] = useState('');
  const navigate = useNavigate();
  const content = useContent();

  const createIncident = useMutation({
    mutationFn: async (input: {
      description: string;
      location: string;
      date: number;
    }) => {
      const orgId = getOrgId();
      const user = getUser();

      if (!user) throw new Error('User not found');

      const incident = await db.incident.create({
        data: {
          userId: user.uid,
          orgId,
          isResolved: false,
          title: `Incident - ${dayjs().format('D MMM YYYY')}`,
          ...input,
        },
      });

      await generateIncidentSummary({
        orgId: incident.orgId,
        incidentId: incident.id,
      });

      return incident;
    },
    onSuccess: async (incident) => {
      analytics.track('incident.create');

      navigate(`/incidents/${incident.id}`);
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  return (
    <div className="mx-auto w-full max-w-2xl space-y-10 md:space-y-14">
      <h1 className="text-left text-2xl font-bold md:text-3xl">New Incident</h1>

      <form
        className="space-y-6 md:space-y-10"
        onSubmit={(event) => {
          event.preventDefault();

          if (!description) return;

          createIncident.mutate({
            description,
            location,
            date: dateToCurrentUnix(date),
          });
        }}
      >
        <div className="rounded-lg border">
          <div className="bg-muted/50 px-6 py-4">
            {content.get('whenDidIncidentOccur')}
          </div>

          <Separator />

          <div className="p-6">
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant={'outline'}
                  className={cn(
                    'w-full justify-start text-left font-normal',
                    !date && 'text-muted-foreground',
                  )}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />

                  {date ? (
                    dayjs(date).format('D MMM YYYY')
                  ) : (
                    <span>{content.get('pickADate')}</span>
                  )}
                </Button>
              </PopoverTrigger>

              <PopoverContent className="w-auto p-0">
                <Calendar
                  mode="single"
                  selected={date}
                  onSelect={(event) => {
                    if (!(event instanceof Date)) return;

                    setDate(event);
                    document.getElementById('hidden')?.focus();
                  }}
                />
              </PopoverContent>
            </Popover>
          </div>
        </div>

        <div className="rounded-lg border">
          <div className="bg-muted/50 px-6 py-4">
            {content.get('whereDidIncidentOccur')}
          </div>

          <Separator />

          <div className="p-6">
            <Textarea
              value={location}
              onChange={(event) => setLocation(event.target.value)}
              placeholder={`${content.get('locationOfIncident')}...`}
              rows={4}
            />
          </div>
        </div>

        <div className="rounded-lg border">
          <div className="bg-muted/50 px-6 py-4">
            {content.get('describeIncident')}
          </div>

          <Separator />

          <div className="p-6">
            <Textarea
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              placeholder={`${content.get('description')}...`}
              rows={10}
            />
          </div>
        </div>

        <Button
          className="w-full"
          type="submit"
          disabled={createIncident.isPending || !description}
        >
          {createIncident.isPending ? (
            <Loader2 className="h-6 w-6 animate-spin" />
          ) : (
            content.get('createIncident')
          )}
        </Button>
      </form>

      <input
        id="hidden"
        className="pointer-events-none fixed left-full top-full -z-50 h-0 w-0 opacity-0"
        type="text"
      />
    </div>
  );
};
