import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Separator } from '~/components';
import { useContent } from '~/content/hooks';
import { Action, db } from '~/db';
import { useLanguage } from '~/language/hooks';
import { getOrgId } from '~/org';
import { formatDateTime } from '~/utils';

export const Actions = () => {
  const content = useContent();
  const { language } = useLanguage();
  const [actions, setActions] = useState<Action[]>([]);

  useEffect(() => {
    const orgId = getOrgId();
    const unsubscribe = db.action.subscribeList({
      orderByKey: 'created',
      orderByValue: 'desc',
      data: {
        orgId,
      },
      onUpdate: setActions,
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="mx-auto w-full max-w-4xl space-y-6">
      <div className="flex flex-col gap-6 rounded-lg border p-6 md:flex-row md:items-center">
        <h1 className="text-2xl font-bold">{content.get('recentActions')}</h1>
      </div>

      {actions.length > 0 && (
        <div className="mx-auto w-full max-w-4xl space-y-6">
          {actions.map((action) => {
            return (
              <div className="rounded-lg border" key={action.id}>
                <div className="flex flex-col gap-2 px-6 py-3 lg:flex-row lg:gap-10">
                  <div className="whitespace-nowrap font-semibold">
                    {formatDateTime(action.created)}
                  </div>
                </div>

                <Separator />

                <div className="space-y-3 p-6">
                  <Link
                    className="inline-block"
                    to={`/contacts/${action.user.id}`}
                  >
                    <div className="font-semibold text-muted-foreground">
                      {action.user.name}
                    </div>
                  </Link>

                  <div>{action.description[language.id]}</div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
