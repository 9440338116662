import { defineContent } from '~/content';

export default {
  alerts: defineContent({
    default: 'Alerts',
    'de-DE': 'Warnmeldungen',
    'es-ES': 'Alertas',
  }),
  alert: defineContent({
    default: 'Alert',
    'de-DE': 'Warnmeldung',
    'es-ES': 'Alerta',
  }),
  noAlertsSent: defineContent({
    default: 'No alerts have been sent',
    'de-DE': 'Es wurden keine Warnmeldungen gesendet',
    'es-ES': 'No se han enviado alertas',
  }),
  alertSent: defineContent({
    default: 'Alert sent',
    'de-DE': 'Warnmeldung gesendet',
    'es-ES': 'Alerta enviada',
  }),
};
