import { Toaster as Sonner } from 'sonner';
import { useTheme } from '~/theme/hooks';

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  const { theme = 'system' } = useTheme();

  return (
    <Sonner
      theme={theme as ToasterProps['theme']}
      className="toaster org"
      toastOptions={{
        classNames: {
          toast:
            'org toast org-[.toaster]:bg-background org-[.toaster]:text-foreground org-[.toaster]:border-border org-[.toaster]:shadow-lg',
          description: 'org-[.toast]:text-muted-foreground',
          actionButton:
            'org-[.toast]:bg-primary org-[.toast]:text-primary-foreground',
          cancelButton:
            'org-[.toast]:bg-muted org-[.toast]:text-muted-foreground',
        },
      }}
      {...props}
    />
  );
};

export { Toaster };
