import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

export const formatDate = (unix: number, format?: string) => {
  const date = convertUnixToDate(unix);

  return dayjs(date).format(format ?? 'D MMM YYYY');
};

export const formatDateTime = (unix: number, format?: string) => {
  return dayjs.unix(unix).format(format ?? 'D MMMM YYYY - HH:mm');
};

export const convertUnixToDate = (unixTimestamp: number) => {
  return dayjs.unix(unixTimestamp).toDate();
};

export const convertUnixToDateString = (
  unixTimestamp: number,
  format?: string,
) => {
  return dayjs.unix(unixTimestamp).format(format ?? 'D MMM YYYY');
};

export const getUnix = (date?: Date) => {
  return dayjs(date).unix();
};

export const dateToCurrentUnix = (date: Date) => {
  const now = new Date();
  const newDate = dayjs(date)
    .set('hour', now.getHours())
    .set('minute', now.getMinutes())
    .set('second', now.getSeconds());

  return newDate.unix();
};
