import { Link } from 'react-router-dom';
import { Button } from '~/components';

export const NotFound = () => {
  return (
    <div className="fixed left-0 top-0 flex h-screen w-screen items-center justify-center">
      <div className="space-y-8 p-6 text-center md:space-y-12">
        <h1 className="text-xl font-bold md:text-2xl">Page not found</h1>

        <div>
          <Link to="/">
            <Button>Go Home</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
