import { useEffect } from 'react';
import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { ContactOrg, db, Org } from '~/db';
import { getUser } from '~/firebase';
import { getOrgId, removeOrgCookie, setOrgCookie } from '../cookie';

const initialState = {
  isReady: false,
  orgId: getOrgId() as string | null,
  orgs: [] as Org[],
  org: null as Org | null,
  contactOrg: null as ContactOrg | null,
};

export const orgStore = create(
  combine(initialState, (set, get) => ({
    setIsReady: (isReady: boolean) => {
      set(() => ({ isReady }));
    },

    setOrg: (org: Org | null) => {
      setOrgCookie(org?.id);

      set(() => ({ org, orgId: org?.id }));
    },

    setOrgs: (orgs: Org[]) => {
      set(() => ({ orgs }));
    },

    isOwner: () => get().contactOrg?.role === 'owner',

    loadOrgs: async () => {
      const user = getUser();
      const contactOrgs = await db.contactOrg.list({
        data: {
          contactId: user?.uid,
        },
      });
      const allOrgs = await Promise.all(
        contactOrgs.map(async (contactOrg) => {
          const org = await db.org.get(contactOrg.orgId);

          return org;
        }),
      );
      const orgs = allOrgs.filter((org) => org !== null);

      set(() => ({ orgs }));
    },

    loadFirstOrg: async () => {
      const user = getUser();
      const contactOrg = await db.contactOrg.first({
        contactId: user?.uid,
      });

      if (!contactOrg) {
        removeOrgCookie();
        set(() => ({ org: null, orgId: null }));
        return;
      }

      const org = await db.org.get(contactOrg.orgId);

      setOrgCookie(org?.id);

      set(() => ({ org, orgId: org?.id, contactOrg }));
    },

    loadOrg: async (id?: string | null | undefined) => {
      const orgId = id || get().orgId;

      if (!orgId) return;

      const org = await db.org.get(orgId);

      if (!org) {
        removeOrgCookie();
      }

      set(() => ({ org }));
    },

    hasOrg: () => !!get().org,
  })),
);

export function useOrg() {
  const store = orgStore();

  useEffect(() => {
    const fn = async () => {
      await store.loadFirstOrg();

      store.setIsReady(true);
    };

    fn();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { ...store };
}
