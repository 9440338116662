import { getFunctions } from 'firebase/functions';
import { firebase } from '~/firebase';
import { getRegion } from '~/region';
import { isNotProduction } from '~/utils';

export const functions = getFunctions(firebase, getRegion());

if (isNotProduction()) {
  // connectFunctionsEmulator(functions, 'localhost', 5001);
}
