import { analytics } from '~/analytics';
import { processIncidentAlert } from '~/api';
import { db } from '~/db';
import { getUser } from '~/firebase';
import { getOrgId } from '~/org';

export const sendAlert = async (incidentId: string) => {
  const user = getUser();
  const orgId = getOrgId();

  if (!user || !orgId) return;

  const alert = await db.alert.create({
    data: {
      orgId,
      incidentId,
      userId: user.uid,
      status: 'pending',
    },
  });

  await processIncidentAlert({
    orgId,
    incidentId,
    alertId: alert.id,
  });

  analytics.track('incident.alert.send');

  return alert;
};
