import { Incident } from '~/db';
import { Language } from '~/language';

type Key = keyof Pick<
  Incident,
  'title' | 'description' | 'location' | 'summary'
>;

export const getIncidentData = (
  incident: Incident | null,
  language: Language,
  key: Key,
) => {
  const object = incident?.data?.[language.id] || incident;

  if (!(object as any)[key]) return incident?.[key];

  return (object as any)[key] as Incident[Key];
};
