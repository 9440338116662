import { useMutation } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import { useState } from 'react';
import { analytics } from '~/analytics';
import { createOrg } from '~/api';
import { getAccountId } from '~/auth';
import { Button, Input } from '~/components';
import { useContent } from '~/content/hooks';
import { useOrg } from '~/org/hooks';
import { sentry } from '~/sentry';

export const CreateOrg = () => {
  const [name, setName] = useState('');
  const { setOrg } = useOrg();
  const content = useContent();

  const mutation = useMutation({
    mutationFn: async ({
      accountId,
      name,
    }: {
      accountId: string;
      name: string;
    }) => {
      const { org } = await createOrg({ accountId, name });

      return org;
    },
    onSuccess: (org) => {
      analytics.track('org.create');
      setOrg(org);
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  return (
    <div className="mx-auto w-full max-w-2xl space-y-10 md:mt-20 md:space-y-14">
      <h1 className="text-center text-2xl font-bold md:text-3xl">
        {content.get('createOrg')}
      </h1>

      <form
        className="space-y-6 md:space-y-10"
        onSubmit={(event) => {
          event.preventDefault();

          if (!name) return;

          const accountId = getAccountId();

          mutation.mutate({ accountId, name });
        }}
      >
        <Input
          value={name}
          onChange={(event) => setName(event.target.value)}
          placeholder={content.get('name')}
        />

        <Button
          className="w-full"
          type="submit"
          disabled={mutation.isPending || !name}
        >
          {mutation.isPending ? (
            <Loader2 className="h-6 w-6 animate-spin" />
          ) : (
            'Create org'
          )}
        </Button>
      </form>
    </div>
  );
};
