import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '~/auth/hooks';

export const Home = () => {
  const { isReady, isSignedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isReady) return;

    if (isSignedIn) {
      navigate('/incidents');
    } else {
      navigate('/sign-in');
    }
  }, [isReady, isSignedIn, navigate]);

  return null;
};
