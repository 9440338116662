import { useAuth } from '~/auth/hooks';
import { requiresPlanLevel } from '../plan';

export function usePlan() {
  const { account } = useAuth();

  const hasPlanLevel = (requiredPlanLevel: number) => {
    const hasIt = requiresPlanLevel(account?.planId, requiredPlanLevel);

    return hasIt;
  };

  const hasEssential = () => {
    return hasPlanLevel(2);
  };

  return {
    hasPlanLevel,
    hasEssential,
  };
}
