import { Update } from '~/db';
import { Language } from '~/language';

type Key = keyof Pick<Update, 'text'>;

export const getUpdateData = (
  update: Update | null,
  language: Language,
  key: Key,
) => {
  const object = update?.data?.[language.id] || update;

  if (!(object as any)[key]) return update?.[key];

  return (object as any)[key] as Update[Key];
};
