import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { cookies } from '~/cookie';

export function useRedirectToDashboard() {
  const navigate = useNavigate();

  useEffect(() => {
    if (cookies.get('auth')) {
      navigate('/');
    }
  }, [navigate]);
}
