import { Link } from 'react-router-dom';
import { useAuth } from '~/auth/hooks';
import { useContent } from '~/content/hooks';
import { LogoIcon } from '~/dashboard/components';
import { LanguageSelector } from '~/language/components';
import { useOrg } from '~/org/hooks';
import { getInitials } from '~/utils';

export const DashboardHeader = () => {
  const { user } = useAuth();
  const { isOwner } = useOrg();
  const content = useContent();
  const mainLinks = [
    {
      path: '/incidents',
      label: content.get('incidents'),
    },
    {
      path: '/contacts',
      label: content.get('contacts'),
    },
  ] as const;

  const ownerLinks = [
    {
      path: '/history',
      label: content.get('history'),
    },
  ] as const;
  const links = isOwner() ? [...mainLinks, ...ownerLinks] : mainLinks;

  return (
    <header className="fixed left-0 right-0 top-0 z-30 border-b bg-background/90 backdrop-blur-lg">
      <div className="flex h-20 items-center gap-4 px-4 md:px-6">
        <nav className="flex h-20 items-center gap-4">
          <Link
            to={links[0].path}
            className="mr-4 items-center gap-2 text-lg font-semibold md:ml-2 md:block md:text-base"
          >
            <LogoIcon />

            <span className="sr-only">EmergencyReport</span>
          </Link>

          {links.map((link) => (
            <Link
              className="hidden px-3 py-6 font-semibold text-muted-foreground transition-colors hover:text-foreground sm:block"
              to={link.path}
              key={link.path}
            >
              {link.label}
            </Link>
          ))}
        </nav>

        <div className="flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
          <div className="ml-auto flex items-center gap-6 font-bold md:gap-8">
            <LanguageSelector />

            <Link to="/account">
              <div className="flex h-12 w-12 items-center justify-center rounded-full border-2 border-muted">
                {user?.displayName && getInitials(user.displayName)}
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div className="relative h-14 w-full overflow-hidden border-t sm:hidden">
        <div className="hide-scrollbar h-20 w-full overflow-x-auto">
          <nav className="flex h-14 items-center gap-6 px-8">
            {links.map((link) => (
              <Link
                className="flex h-14 items-center text-sm font-semibold text-gray-500 hover:text-black dark:text-gray-400 dark:hover:text-white md:text-base"
                to={link.path}
                key={link.path}
              >
                {link.label}
              </Link>
            ))}

            <div className="h-full w-10 shrink-0" />
          </nav>
        </div>
      </div>
    </header>
  );
};
