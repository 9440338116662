import { Incident } from '~/db';
import { useLanguage } from '~/language/hooks';

type Key = keyof Pick<
  Incident,
  'title' | 'description' | 'location' | 'summary'
>;

export const useIncidentData = (incident: Incident | null) => {
  const { language } = useLanguage();

  const data = (key: Key) => {
    const object = incident?.data?.[language.id] || incident;

    if (!(object as any)[key]) return incident?.[key];

    return (object as any)[key] as Incident[Key];
  };

  return data;
};
