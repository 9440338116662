import { defineContent } from '~/content';

export default {
  contacts: defineContent({
    default: 'Contacts',
    'de-DE': 'Kontakte',
    'es-ES': 'Contactos',
  }),
  invites: defineContent({
    default: 'Invites',
    'de-DE': 'Einladungen',
    'es-ES': 'Invitaciones',
  }),
  addContact: defineContent({
    default: 'Add contact',
    'de-DE': 'Kontakt hinzufügen',
    'es-ES': 'Agregar contacto',
  }),
  inviteContact: defineContent({
    default: 'Invite contact',
    'de-DE': 'Kontakt einladen',
    'es-ES': 'Invitar contacto',
  }),
  newContact: defineContent({
    default: 'New contact',
    'de-DE': 'Neuer Kontakt',
    'es-ES': 'Nuevo contacto',
  }),
  removeContact: defineContent({
    default: 'Remove contact',
    'de-DE': 'Kontakt entfernen',
    'es-ES': 'Eliminar contacto',
  }),
  enterTheirEmail: defineContent({
    default: 'Enter their email address',
    'de-DE': 'Geben Sie ihre E-Mail-Adresse ein',
    'es-ES': 'Ingrese su dirección de correo electrónico',
  }),
  deleteContactMessage: defineContent({
    default:
      'This will permanently remove this contact from the org. This action cannot be undone.',
    'de-DE':
      'Dies entfernt diesen Kontakt dauerhaft aus der Gruppe. Diese Aktion kann nicht rückgängig gemacht werden.',
    'es-ES':
      'Esto eliminará permanentemente este contacto de la organización. Esta acción no se puede deshacer.',
  }),
  invalidCode: defineContent({
    default: 'Invalid code',
    'de-DE': 'Ungültiger Code',
    'es-ES': 'Código inválido',
  }),
  thisCodeInvalid: defineContent({
    default: 'This code is invalid or has expired',
    'de-DE': 'Dieser Code ist ungültig oder abgelaufen',
    'es-ES': 'Este código es inválido o ha expirado',
  }),
  youveBeenInvited: defineContent({
    default: "You've been invited to join the following organisation:",
    'de-DE': 'Sie wurden eingeladen, der folgenden Organisation beizutreten:',
    'es-ES': 'Has sido invitado a unirte a la siguiente organización:',
  }),
  acceptInvite: defineContent({
    default: 'Accept Invite',
    'de-DE': 'Einladung annehmen',
    'es-ES': 'Aceptar invitación',
  }),
  done: defineContent({
    default: 'Done',
    'de-DE': 'Erledigt',
    'es-ES': 'Hecho',
  }),
};
