import { useMutation, useQuery } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { analytics } from '~/analytics';
import { removeContact } from '~/api';
import { useAuth } from '~/auth/hooks';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Badge,
  Button,
  OriginalButton,
} from '~/components';
import { useContent } from '~/content/hooks';
import { db } from '~/db';
import { getOrgId } from '~/org';
import { useOrg } from '~/org/hooks';
import { sentry } from '~/sentry';
import { useParams } from '~/utils/hooks';

export const Contact = () => {
  const { contactId } = useParams<{ contactId: string }>();
  const orgId = getOrgId();
  const { isOwner } = useOrg();
  const { user } = useAuth();
  const navigate = useNavigate();
  const content = useContent();

  const { data: contact } = useQuery({
    queryKey: ['contact', contactId],
    queryFn: async () => {
      const orgContact = await db.orgContact.get(contactId, {
        orgId,
        contactId,
      });

      return orgContact?.contact;
    },
  });

  const remove = useMutation({
    mutationFn: async () => {
      await removeContact({
        orgId,
        contactId,
      });
    },
    onSuccess: () => {
      analytics.track('contact.delete');

      navigate('/contacts');
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  if (!user || !contact) return null;

  const isCurrentUser = contact.userId === user.uid;

  return (
    <div className="mx-auto grid w-full max-w-xl gap-6">
      <div className="w-full space-y-2 rounded-lg border p-6">
        <div className="flex w-full items-center gap-4">
          <h1 className="text-2xl font-bold">{contact.name}</h1>

          <div>
            {isCurrentUser && (
              <Badge variant="secondary">{content.get('you')}</Badge>
            )}
          </div>
        </div>

        <div className="text-muted-foreground">{contact.email}</div>

        {contact.phone && (
          <div className="text-muted-foreground">{contact.phone}</div>
        )}
      </div>

      {isOwner() ? (
        <div className="flex flex-col gap-6 rounded-lg border p-6 text-center md:flex-row">
          {isCurrentUser && (
            <Link to={`/contacts/${contact.id}/edit`}>
              <Button variant="outline" size="sm">
                {content.get('edit')}
              </Button>
            </Link>
          )}

          {!isCurrentUser && (
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button variant="outline" size="sm" disabled={remove.isPending}>
                  {remove.isPending ? (
                    <Loader2 className="h-6 w-6 animate-spin" />
                  ) : (
                    content.get('removeContact')
                  )}
                </Button>
              </AlertDialogTrigger>

              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>
                    {content.get('areYouSure')}
                  </AlertDialogTitle>

                  <AlertDialogDescription className="pb-6">
                    {content.get('deleteContactMessage')}
                  </AlertDialogDescription>
                </AlertDialogHeader>

                <AlertDialogFooter>
                  <AlertDialogCancel>{content.get('cancel')}</AlertDialogCancel>

                  <AlertDialogAction asChild>
                    <OriginalButton
                      variant="destructive"
                      onClick={() => remove.mutate()}
                    >
                      {content.get('removeContact')}
                    </OriginalButton>
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          )}
        </div>
      ) : (
        isCurrentUser && (
          <div className="flex flex-col gap-6 rounded-lg border p-6 text-center md:flex-row">
            <Link to={`/contacts/${contact.id}/edit`}>
              <Button variant="outline" size="sm">
                {content.get('edit')}
              </Button>
            </Link>
          </div>
        )
      )}
    </div>
  );
};
