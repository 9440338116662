import { defineContent } from '~/content';

export default {
  signIn: defineContent({
    default: 'Sign in',
    'de-DE': 'Anmelden',
    'es-ES': 'Iniciar sesión',
  }),
  signUp: defineContent({
    default: 'Sign up',
    'de-DE': 'Registrieren',
    'es-ES': 'Regístrate',
  }),
  signOut: defineContent({
    default: 'Sign out',
    'de-DE': 'Abmelden',
    'es-ES': 'Cerrar sesión',
  }),
  continueWithGoogle: defineContent({
    default: 'Continue with Google',
    'de-DE': 'Mit Google fortfahren',
    'es-ES': 'Continuar con Google',
  }),
  password: defineContent({
    default: 'Password',
    'de-DE': 'Passwort',
    'es-ES': 'Contraseña',
  }),
  dontHaveAnAccount: defineContent({
    default: "Don't have an account?",
    'de-DE': 'Sie haben noch kein Konto?',
    'es-ES': '¿No tienes una cuenta?',
  }),
  alreadyHaveAnAccount: defineContent({
    default: 'Already have an account?',
    'de-DE': 'Sie haben bereits ein Konto?',
    'es-ES': '¿Ya tienes una cuenta?',
  }),
};
