import { AnimatePresence } from 'framer-motion';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Actions } from '~/action/components';
import { Alert, Alerts } from '~/alert/components';
import {
  Account,
  ProtectedPage,
  SignIn,
  SignOut,
  SignUp,
} from '~/auth/components';
import {
  Contact,
  ContactInvite,
  Contacts,
  CreateContact,
  EditContact,
} from '~/contact/components';
import { DashboardPage } from '~/dashboard/components';
import { Home } from '~/home/components';
import {
  CreateIncident,
  EditIncident,
  Incident,
  Incidents,
  ResolvedIncidents,
  SendIncidentAlert,
} from '~/incident/components';
import { NotFound } from '~/not-found/components';
import { Checkout, Plans } from '~/plan/components';
import { QueryProvider } from '~/query/components';
import { Theme } from '~/theme/components';
import { CreateUpdate, EditUpdate } from '~/update/components';

export const App = () => {
  const location = useLocation();

  return (
    <>
      <AnimatePresence mode="wait">
        <QueryProvider>
          <Theme defaultTheme="system">
            <Routes location={location} key={location.pathname}>
              {/* home */}
              <Route path="/" element={<Home />} />

              {/* auth */}
              <Route path="/sign-in" element={<SignIn />} />
              <Route path="/sign-up" element={<SignUp />} />
              <Route path="/sign-out" element={<SignOut />} />

              {/* incidents */}
              <Route
                path="/incidents"
                element={
                  <DashboardPage>
                    <Incidents />
                  </DashboardPage>
                }
              />
              <Route
                path="/incidents/new"
                element={
                  <DashboardPage>
                    <CreateIncident />
                  </DashboardPage>
                }
              />
              <Route
                path="/incidents/resolved"
                element={
                  <DashboardPage>
                    <ResolvedIncidents />
                  </DashboardPage>
                }
              />
              <Route
                path="/incidents/:incidentId"
                element={
                  <DashboardPage>
                    <Incident />
                  </DashboardPage>
                }
              />
              <Route
                path="/incidents/:incidentId/edit"
                element={
                  <DashboardPage>
                    <EditIncident />
                  </DashboardPage>
                }
              />
              <Route
                path="/incidents/:incidentId/send-alert"
                element={
                  <DashboardPage>
                    <SendIncidentAlert />
                  </DashboardPage>
                }
              />
              <Route
                path="/incidents/:incidentId/updates/new"
                element={
                  <DashboardPage>
                    <CreateUpdate />
                  </DashboardPage>
                }
              />
              <Route
                path="/incidents/:incidentId/updates/:updateId/edit"
                element={
                  <DashboardPage>
                    <EditUpdate />
                  </DashboardPage>
                }
              />
              <Route
                path="/incidents/:incidentId/alerts"
                element={
                  <DashboardPage>
                    <Alerts />
                  </DashboardPage>
                }
              />
              <Route
                path="/incidents/:incidentId/alerts/:alertId"
                element={
                  <DashboardPage>
                    <Alert />
                  </DashboardPage>
                }
              />

              {/* contacts */}
              <Route
                path="/contacts"
                element={
                  <DashboardPage>
                    <Contacts />
                  </DashboardPage>
                }
              />
              <Route
                path="/contacts/new"
                element={
                  <DashboardPage>
                    <CreateContact />
                  </DashboardPage>
                }
              />
              <Route
                path="/contacts/:contactId"
                element={
                  <DashboardPage>
                    <Contact />
                  </DashboardPage>
                }
              />
              <Route
                path="/contacts/:contactId/edit"
                element={
                  <DashboardPage>
                    <EditContact />
                  </DashboardPage>
                }
              />

              {/* history */}
              <Route
                path="/history"
                element={
                  <DashboardPage>
                    <Actions />
                  </DashboardPage>
                }
              />

              {/* account */}
              <Route
                path="/account"
                element={
                  <DashboardPage>
                    <Account />
                  </DashboardPage>
                }
              />

              {/* join */}
              <Route
                path="/join/:code"
                element={
                  <ProtectedPage>
                    <ContactInvite />
                  </ProtectedPage>
                }
              />

              {/* plans */}
              <Route
                path="/plans"
                element={
                  <ProtectedPage>
                    <main className="px-6 py-28 md:px-20 md:py-40">
                      <Plans />
                    </main>
                  </ProtectedPage>
                }
              />
              <Route
                path="/plans/pay/checkout"
                element={
                  <ProtectedPage>
                    <Checkout />
                  </ProtectedPage>
                }
              />

              {/* not found */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Theme>
        </QueryProvider>
      </AnimatePresence>
    </>
  );
};
