import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Separator } from '~/components';
import { useContent } from '~/content/hooks';
import { db, Incident } from '~/db';
import { useLanguage } from '~/language/hooks';
import { getOrgId } from '~/org';
import { useOrg } from '~/org/hooks';
import { formatDateTime, numberWithCommas } from '~/utils';
import { getIncidentData } from '../utils';

export const Incidents = () => {
  const { isOwner } = useOrg();
  const content = useContent();
  const { language } = useLanguage();
  const [incidents, setIncidents] = useState<Incident[]>([]);

  useEffect(() => {
    const orgId = getOrgId();
    const unsubscribe = db.incident.subscribeList({
      data: {
        orgId,
      },
      where: {
        key: 'isResolved',
        operator: '==',
        value: false,
      },
      orderByKey: 'created',
      orderByValue: 'desc',
      onUpdate: setIncidents,
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="mx-auto w-full max-w-4xl space-y-6">
      <div className="flex flex-col gap-6 rounded-lg border p-6 md:flex-row md:items-center">
        <h1 className="text-2xl font-bold">
          {content.get('incidents')} ({numberWithCommas(incidents.length)})
        </h1>

        <Separator className="md:hidden" />

        <Link className="md:ml-auto" to="/incidents/new">
          <Button className="w-full">{content.get('reportNewIncident')}</Button>
        </Link>
      </div>

      {incidents.length > 0 ? (
        <div className="mx-auto w-full max-w-4xl overflow-hidden rounded-lg border">
          {incidents.map((incident, incidentIndex) => {
            return (
              <div className="hover:bg-muted/40" key={incident.id}>
                {incidentIndex !== 0 && <Separator />}

                <Link to={`/incidents/${incident.id}`}>
                  <div className="flex flex-col gap-2 px-8 py-6 lg:flex-row lg:items-center lg:gap-14 lg:py-8 xl:py-10">
                    <div className="font-semibold">
                      {getIncidentData(incident, language, 'title')}
                    </div>

                    <div className="whitespace-nowrap text-muted-foreground lg:ml-auto">
                      {formatDateTime(incident.updated, 'HH:mm - D MMM YYYY')}
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="text-balance rounded-lg border px-6 py-40 text-center text-lg font-medium text-muted-foreground">
          {content.get('noIncidentsReported')}
        </div>
      )}

      {isOwner() && (
        <div className="rounded-lg border p-6 text-center">
          <Link to="/incidents/resolved">
            <Button size="sm" variant="outline">
              {content.get('viewResolved')}
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
};
