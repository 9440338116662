import { useEffect, useState } from 'react';
import { useAuth } from '~/auth/hooks';
import { Badge, Separator } from '~/components';
import { useContent } from '~/content/hooks';
import { Alert as AlertType, ContactAlert, db } from '~/db';
import { getOrgId } from '~/org';
import { cn } from '~/style';
import { formatDateTime } from '~/utils';
import { useParams } from '~/utils/hooks';

export const Alert = () => {
  const { incidentId, alertId } = useParams<{
    incidentId: string;
    alertId: string;
  }>();
  const orgId = getOrgId();
  const { user } = useAuth();
  const content = useContent();
  const [alert, setAlert] = useState<AlertType | null>(null);
  const [contactAlerts, setContactAlerts] = useState<ContactAlert[]>([]);

  useEffect(() => {
    const unsubscribe = db.alert.subscribe({
      id: alertId,
      data: {
        orgId,
        incidentId,
      },
      onUpdate: setAlert,
    });

    return () => {
      unsubscribe();
    };
  }, [alertId, orgId, incidentId]);

  useEffect(() => {
    const unsubscribe = db.contactAlert.subscribeList({
      data: {
        orgId,
        incidentId,
        alertId,
      },
      onUpdate: setContactAlerts,
    });

    return () => {
      unsubscribe();
    };
  }, [alertId, orgId, incidentId]);

  if (!alert) return null;

  return (
    <div className="mx-auto max-w-4xl space-y-6">
      <div className="mx-auto mb-4">
        <h1 className="text-2xl font-bold md:text-3xl">
          {content.get('alert')}
        </h1>
      </div>

      <div className="rounded-lg border">
        <div className="space-y-6 p-6">
          <div className="space-y-2">
            <div className="text-lg font-bold">{content.get('date')}</div>

            <div className="text-muted-foreground">
              {formatDateTime(alert.updated, 'HH:mm - D MMM YYYY')}
            </div>
          </div>

          <div className="space-y-2">
            <div className="text-lg font-bold">{content.get('status')}</div>

            <div>
              <div
                className={cn(
                  'mt-2 inline-block rounded-full px-5 py-1 text-center text-sm font-semibold',
                  {
                    'bg-[rgb(43,172,110)] text-white':
                      alert.status === 'complete',
                    'bg-gray-600 text-white': alert.status !== 'complete',
                  },
                )}
              >
                {alert.status === 'complete'
                  ? content.get('sent')
                  : content.get('sending')}
              </div>
            </div>
          </div>
        </div>
      </div>

      {contactAlerts.length !== 0 && (
        <>
          <div className="pt-10">
            <h2 className="text-xl font-bold">
              {content.get('contacts')} ({contactAlerts?.length || 0})
            </h2>
          </div>

          <div className="overflow-hidden rounded-lg border">
            {contactAlerts?.map((contactAlert, contactAlertIndex) => {
              const isCurrentUser = contactAlert.contactId === user?.uid;

              if (!contactAlert.contact) return null;

              return (
                <div key={contactAlert.id}>
                  {contactAlertIndex !== 0 && <Separator />}

                  <div className="flex flex-col gap-2 px-8 py-6 lg:flex-row lg:items-center lg:gap-14 lg:py-6 xl:py-8">
                    <div className="space-y-2">
                      <div className="flex items-center gap-4">
                        <div className="text-lg">
                          {contactAlert.contact.name}
                        </div>

                        {isCurrentUser && (
                          <div>
                            <Badge variant="secondary">
                              {content.get('you')}
                            </Badge>
                          </div>
                        )}
                      </div>

                      <div className="text-muted-foreground">
                        {contactAlert.contact.email}
                      </div>

                      {contactAlert.contact.phone && (
                        <div className="text-muted-foreground">
                          {contactAlert.contact.phone}
                        </div>
                      )}
                    </div>

                    <div className="ml-auto mt-8 flex items-center gap-6 md:mt-0">
                      {contactAlert.status === 'complete' && (
                        <div className="ml-auto">
                          {formatDateTime(contactAlert.updated, 'HH:mm')}
                        </div>
                      )}

                      <div
                        className={cn(
                          'inline-block rounded-full px-5 py-1 text-center text-sm font-semibold',
                          {
                            'bg-[rgb(43,172,110)] text-white':
                              contactAlert.status === 'complete',
                            'bg-gray-600 text-white':
                              contactAlert.status !== 'complete',
                          },
                        )}
                      >
                        {contactAlert.status === 'complete'
                          ? content.get('sent')
                          : content.get('sending')}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
