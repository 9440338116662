import { initializeApp } from 'firebase/app';
import { isProduction } from '~/utils';

const configDev = {
  apiKey: 'AIzaSyBSQe9C8oukttF3r0rKdzwdSWC7iCrvXsU',
  authDomain: 'emergencyreport-uk-dev.firebaseapp.com',
  projectId: 'emergencyreport-uk-dev',
  storageBucket: 'emergencyreport-uk-dev.appspot.com',
  messagingSenderId: '29995770265',
  appId: '1:29995770265:web:b06ae1898cb318befeb308',
};

const configProd = {
  apiKey: 'AIzaSyCDS0oRI_40vEVmEU7BDw3MhNIEu3L5sS8',
  authDomain: 'emergencyreport-uk-prod.firebaseapp.com',
  projectId: 'emergencyreport-uk-prod',
  storageBucket: 'emergencyreport-uk-prod.appspot.com',
  messagingSenderId: '1064979365116',
  appId: '1:1064979365116:web:8e97b6d9d661915095eb79',
};

const config = isProduction() ? configProd : configDev;

// Save the original console.log function
const originalConsoleLog = console.log;

console.log = function (...args) {
  // Check if the log message contains the word "heartbeats"
  if (
    args.some((arg) => typeof arg === 'string' && arg.includes('heartbeats'))
  ) {
    return; // Skip the log message
  }

  // Otherwise, use the original console.log
  originalConsoleLog.apply(console, args);
};

export const firebase = initializeApp(config);
