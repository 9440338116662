import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { formatAudioTime } from '~/utils';

const initialState = {
  isPlaying: false,
  duration: 0,
  durationFormatted: '00:00',
  currentTime: 0,
  currentTimeFormatted: '00:00',
  currentPercentage: 0,
};

export const useAudioPlayer = create(
  combine(initialState, (set, get) => ({
    play: () => {
      set({ isPlaying: true });
    },

    pause: () => {
      set({ isPlaying: false });
    },

    toggle: () => {
      set({ isPlaying: !get().isPlaying });
    },

    setDuration: (seconds: number) => {
      const durationFormatted = formatAudioTime(seconds);

      set({ duration: seconds, durationFormatted });
    },

    setCurrentTime: (currentTime: number) => {
      const currentTimeFormatted = formatAudioTime(currentTime);
      const currentPercentage = (currentTime / get().duration) * 100;

      set({ currentTime, currentTimeFormatted, currentPercentage });
    },
  })),
);
