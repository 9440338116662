import { plans } from '~/plan';
import { PlanCard } from '~/plan/components';

export const Plans = ({ title, text }: { title?: string; text?: string }) => {
  return (
    <div className="space-y-20 md:space-y-24">
      {title ||
        (text && (
          <div>
            {title && (
              <h2 className="mb-4 text-balance text-center text-2xl font-bold leading-normal md:text-4xl lg:text-5xl lg:leading-normal">
                {title}
              </h2>
            )}

            {text && (
              <p className="mx-auto max-w-sm text-balance text-center text-lg text-muted-foreground md:text-lg lg:text-xl lg:leading-relaxed">
                {text}
              </p>
            )}
          </div>
        ))}

      <div className="mx-auto grid max-w-6xl gap-12 md:grid-cols-2 md:gap-8 xl:grid-cols-3">
        {plans.map((plan) => (
          <PlanCard plan={plan} key={plan.id} />
        ))}
      </div>
    </div>
  );
};
