import cookie from 'js-cookie';
import { isProduction } from '~/utils';

const options = isProduction()
  ? {
      domain: 'emergencyreport.co.uk',
    }
  : {
      domain: 'localhost',
      expires: 365,
    };

export const cookies = {
  get: cookie.get,

  set: (name: string, value: string) => {
    return cookie.set(name, value, options);
  },

  remove: (name: string) => {
    return cookie.remove(name, options);
  },
};
