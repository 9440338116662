import * as Sentry from '@sentry/react';
import { isNotProduction } from '~/utils';

export const initSentry = async () => {
  if (isNotProduction()) return;

  Sentry.init({
    dsn: 'https://8d8eca9549d57c32fd0387b7999bc28e@o4507225025740800.ingest.de.sentry.io/4507893302886480',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/basefitness\.app\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  // Sentry.setTag('version', version.text);
  // Sentry.setTag('version.major', version.major);
  // Sentry.setTag('version.minor', version.minor);
  // Sentry.setTag('version.webVersion', version.webVersion);
  // Sentry.setTag('platform', platform);
};

export const captureError = (error: any) => {
  if (isNotProduction()) {
    console.error(error);

    return;
  }

  Sentry.captureException(error);
};

export const sentry = {
  setUser: Sentry.setUser,
  setTag: Sentry.setTag,
  captureError,
};
